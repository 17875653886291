import { Component, Input, OnInit, OnChanges, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { CurrentConstructionScopeDto } from 'src/app/services/property-matrixV2/models';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-current-construction-scope',
  templateUrl: './current-construction-scope.component.html',
  styleUrls: ['./current-construction-scope.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss'],
  providers: [DecimalPipe]
})
export class CurrentConstructionScopeComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() planningReportId: string;
  @Input() potentialUsableProperty: number;

  @Output() currentConstructionScopeChange = new EventEmitter<CurrentConstructionScopeDto>();

  currentConstructionScopeData: CurrentConstructionScopeDto = {
    currentOpenSpaceRequired: 0,
    currentDensity: 0,
    currentFloorAreaRatio: 0,
    currentHeight: 0,
    currentCoverage: 0,
    currentParkingSpaces: 0,
    currentVisitorsParkingSpaces: 0,
    currentParkingSpacesPerHundredSquareMeters: 0,
    currentMaximumOpenSpaceRequired: 0,
    currentMaximumUnitsOnUsableSpace: 0,
    currentMaximumSquareMetersBasedOnFloorAreaRatio: 0,
    currentMaximumSquareMetersBasedOnCoverageAndHeight: 0,
    currentMaximumUnitsAverageSize: 0,
    currentParkingSpacesWithMaxRights: 0,
    currentVisitorsParkingSpacesWithMaxRights: 0,
    currentParkingSpacesPerHundredSquareMetersWithMaxRights: 0,
    currentTotalParkingSpacesWithMaxRights: 0,
    currentParkingSpacesAvailableOnVacantLandWithMaxRights: 0,
  };
  squareMetersConversion: number = 10000;

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService,
    private decimalPipe: DecimalPipe
  ) { }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.loadCurrentConstructionScope();
  }

  ngOnChanges(): void {
    this.calculateValues();
  }

  async loadCurrentConstructionScope(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetCurrentConstructionScopeGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response: any) => {
        this.currentConstructionScopeData = response;
        if (this.currentConstructionScopeData.currentDensity) {
          const newCurrentDensity: string = this.decimalPipe.transform(this.currentConstructionScopeData.currentDensity, '1.2-2');
        }
        if (this.currentConstructionScopeData.currentOpenSpaceRequired) {
          this.decimalPipe.transform(this.currentConstructionScopeData.currentOpenSpaceRequired, '1.2-2');
        }
        this.calculateValues();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load current construction scope data.');
      }
    });
  }

  calculateValues(): void {
    if (this.currentConstructionScopeData) {
      this.currentConstructionScopeData.currentMaximumOpenSpaceRequired = Math.max(0, Math.floor(this.potentialUsableProperty * (this.currentConstructionScopeData.currentOpenSpaceRequired / 100) * 10000) / 10000);
      this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace = Math.max(0, Math.floor(this.potentialUsableProperty * this.currentConstructionScopeData.currentDensity));
      this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnFloorAreaRatio = Math.max(0, Math.floor(this.potentialUsableProperty * this.currentConstructionScopeData.currentFloorAreaRatio * this.squareMetersConversion));
      this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnCoverageAndHeight = Math.max(0, Math.floor(this.potentialUsableProperty * this.currentConstructionScopeData.currentHeight * (this.currentConstructionScopeData.currentCoverage / 100) * this.squareMetersConversion));
      this.currentConstructionScopeData.currentMaximumUnitsAverageSize = Math.max(0, Math.floor(this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnCoverageAndHeight / this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace));

      this.currentConstructionScopeData.currentParkingSpacesWithMaxRights = Math.max(0, Math.floor(this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace * this.currentConstructionScopeData.currentParkingSpaces));
      this.currentConstructionScopeData.currentVisitorsParkingSpacesWithMaxRights = Math.max(0, Math.floor(this.currentConstructionScopeData.currentVisitorsParkingSpaces * this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace));
      this.currentConstructionScopeData.currentParkingSpacesPerHundredSquareMetersWithMaxRights = Math.max(0, Math.floor(this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnFloorAreaRatio / 100 * this.currentConstructionScopeData.currentParkingSpacesPerHundredSquareMeters));
      this.currentConstructionScopeData.currentTotalParkingSpacesWithMaxRights = Number(this.decimalPipe.transform(Math.max(0, Math.floor(this.currentConstructionScopeData.currentParkingSpacesWithMaxRights + this.currentConstructionScopeData.currentParkingSpacesPerHundredSquareMetersWithMaxRights)), '1.2-2'));
      this.currentConstructionScopeData.currentParkingSpacesAvailableOnVacantLandWithMaxRights = Math.max(0, Math.floor((1 - (this.currentConstructionScopeData.currentCoverage / 100)) * ((this.potentialUsableProperty - this.currentConstructionScopeData.currentMaximumOpenSpaceRequired) * this.squareMetersConversion) / 23));
      this.currentConstructionScopeChange.emit(this.currentConstructionScopeData);
    }
  }

  private convertArea(area: number, fromUnit: string, toUnit: string): number {
    if (fromUnit === 'ha' && toUnit === 'm²') {
      return area * 10000;
    } else if (fromUnit === 'm²' && toUnit === 'ha') {
      return area / 10000;
    } else {
      return area;
    }
  }

  private roundToFixed(value: number, decimals: number): number {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
  }
}

import { ExistingZoningControlsDto, LandUseDto, SchemeDto, UseRightTypeDto, ZoningDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { LookupService, PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-existing-zoning-controls',
  templateUrl: './existing-zoning-controls.component.html',
  styleUrls: ['./existing-zoning-controls.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ExistingZoningControlsComponent implements OnInit {

  @Input() planningReportId: string;
  @Output() existingZoningControlsChange = new EventEmitter<ExistingZoningControlsDto>();

  existingZoningControlsData: ExistingZoningControlsDto = {
    schemeId: null,
    zoningId: null,
    usesPermittedOnSiteIds: [],
    approvedAdditionalUseIds: [],
    currentPlanningApplication: "",
    addendum: "",
    coveragePermitted: 0,
    heightPermitted: 0,
    floorAreaRatio: 0,
    density: 0,
    minimumErfSize: 0,
    currentPermittedBulk: 0,
  };
  schemeData: SchemeDto[];
  zoningData: ZoningDto[];
  usesPermittedOnSite: LandUseDto[];
  approvedAdditionalUses: LandUseDto[];

  usesPermittedOnSiteTbcGuid: string | null = null;
  usesPermittedOnSiteNoneGuid: string | null = null;
  approvedAdditionalUsesTbcGuid: string | null = null;
  approvedAdditionalUsesNoneGuid: string | null = null;

  consentUsesUseRightTypeId: string | null = null;
  usesPermittedOnSiteUseRightTypeId: string | null = null;

  possibleAdditionalUses: string | null = null;
  possibleUsesPermittedOnSite: string | null = null;

  constructor(
    private snackBar: MatSnackBar,
    private lookupService: LookupService,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadUseRightTypes();
  }

  async loadUseRightTypes(): Promise<void> {
    this.lookupService.apiV1LookupGetUseRightTypesGet().subscribe({
      next: (response: UseRightTypeDto[]) => {
        this.consentUsesUseRightTypeId = response.find(item => item.description === 'Consent Uses (Additional uses/Secondary Rights/Uses/Special Consent/Written Consent A or B)')?.id;
        this.usesPermittedOnSiteUseRightTypeId = response.find(item => item.description === 'Uses permitted on site (Primary rights/Uses)')?.id;
        this.loadExistingZoningControls();
      }
    });
  }

  async loadExistingZoningControls(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetExistingZoningControlsGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: async (response: ExistingZoningControlsDto) => {
        this.existingZoningControlsData = response;
        if (this.existingZoningControlsData.municipality != null && this.existingZoningControlsData.municipality != '') {
          this.loadSelectedScheme();
        } else {
          this.loadAllSchemes();
        }
        if (this.existingZoningControlsData?.zoningId != null) {
          this.loadZoningData();
          this.loadPossibleUsesPermittedOnSite();
          this.loadPossibleAdditionalUses();
        }
        if (this.existingZoningControlsData?.usesPermittedOnSiteIds != null) {
          this.loadUsesPermittedOnSite();
        }
        if (this.existingZoningControlsData?.approvedAdditionalUseIds != null) {
          this.loadApprovedAdditionalUses();
        }
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load existing zoning controls data.');
      }
    });
  }

  async loadSelectedScheme(): Promise<void> {
    this.lookupService.apiV1LookupGetSchemeDataGet({
      metroDescription: this.existingZoningControlsData?.municipality
    }).subscribe({
      next: (response: SchemeDto[]) => {
        const noneEntry = response.filter(item => item.description === 'None');
        const tbcEntry = response.filter(item => item.description === 'TBC');
        const otherEntries = response.filter(item => item.description !== 'None' && item.description !== 'TBC');

        const sortedEntries = otherEntries.sort((a, b) => a.description.localeCompare(b.description));

        const finalSortedEntries = [...noneEntry, ...tbcEntry, ...sortedEntries];

        this.schemeData = finalSortedEntries;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load selected scheme.');
      }
    });
  }

  async loadAllSchemes(): Promise<void> {
    this.lookupService.apiV1LookupGetAllSchemesGet().subscribe({
      next: (response: SchemeDto[]) => {
        const noneEntry = response.filter(item => item.description === 'None');
        const tbcEntry = response.filter(item => item.description === 'TBC');
        const otherEntries = response.filter(item => item.description !== 'None' && item.description !== 'TBC');

        const sortedEntries = otherEntries.sort((a, b) => a.description.localeCompare(b.description));

        const finalSortedEntries = [...noneEntry, ...tbcEntry, ...sortedEntries];

        this.schemeData = finalSortedEntries;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load all schemes.');
      }
    });
  }

  async loadZoningData(): Promise<void> {
    this.lookupService.apiV1LookupGetZoningDataGet({
      schemeId: this.existingZoningControlsData?.schemeId
    }).subscribe({
      next: (response: ZoningDto[]) => {
        const noneEntry = response.filter(item => item.description === 'None');
        const tbcEntry = response.filter(item => item.description === 'TBC');
        const otherEntries = response.filter(item => item.description !== 'None' && item.description !== 'TBC');

        const sortedEntries = otherEntries.sort((a, b) => a.description.localeCompare(b.description));

        const finalSortedEntries = [...noneEntry, ...tbcEntry, ...sortedEntries];

        this.zoningData = finalSortedEntries;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load zoning data.');
      }
    });
  }

  async loadPossibleUsesPermittedOnSite(): Promise<void> {
    this.lookupService.apiV1LookupGetLandUsesBySchemeZoningUseRightGet({
      schemeId: this.existingZoningControlsData?.schemeId,
      zoningId: this.existingZoningControlsData?.zoningId,
      useRightTypeId: this.usesPermittedOnSiteUseRightTypeId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        const entries = response
          .filter(item => item.descriptionLong !== 'None' && item.descriptionLong !== 'TBC')
          .sort((a, b) => a.descriptionLong.localeCompare(b.descriptionLong));

        this.possibleUsesPermittedOnSite = entries.map(item => item.descriptionLong).join('\n');
      }
    });
  }

  async loadUsesPermittedOnSite(): Promise<void> {
    this.lookupService.apiV1LookupGetLandUsesBySchemeGet({
      schemeId: this.existingZoningControlsData?.schemeId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        const noneEntry = response.find(item => item.descriptionLong === 'None');
        const tbcEntry = response.find(item => item.descriptionLong === 'TBC');
        const otherEntries = response.filter(item => item.descriptionLong !== 'None' && item.descriptionLong !== 'TBC');

        if (noneEntry) {
          this.usesPermittedOnSiteNoneGuid = noneEntry.id;
        }
        if (tbcEntry) {
          this.usesPermittedOnSiteTbcGuid = tbcEntry.id;
        }

        const sortedEntries = otherEntries.sort((a, b) => a.descriptionLong.localeCompare(b.descriptionLong));
        const finalSortedEntries = [...(noneEntry ? [noneEntry] : []), ...(tbcEntry ? [tbcEntry] : []), ...sortedEntries];

        this.usesPermittedOnSite = finalSortedEntries;
      }
    });
  }

  async loadPossibleAdditionalUses(): Promise<void> {
    this.lookupService.apiV1LookupGetLandUsesBySchemeZoningUseRightGet({
      schemeId: this.existingZoningControlsData?.schemeId,
      zoningId: this.existingZoningControlsData?.zoningId,
      useRightTypeId: this.consentUsesUseRightTypeId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        const entries = response
          .filter(item => item.descriptionLong !== 'None' && item.descriptionLong !== 'TBC')
          .sort((a, b) => a.descriptionLong.localeCompare(b.descriptionLong));

        this.possibleAdditionalUses = entries.map(item => item.descriptionLong).join('\n');
      }
    });
  }

  async loadApprovedAdditionalUses(): Promise<void> {
    this.lookupService.apiV1LookupGetLandUsesBySchemeGet({
      schemeId: this.existingZoningControlsData?.schemeId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        const noneEntry = response.find(item => item.descriptionLong === 'None');
        const tbcEntry = response.find(item => item.descriptionLong === 'TBC');
        const otherEntries = response.filter(item => item.descriptionLong !== 'None' && item.descriptionLong !== 'TBC');

        if (noneEntry) {
          this.approvedAdditionalUsesNoneGuid = noneEntry.id;
        }
        if (tbcEntry) {
          this.approvedAdditionalUsesTbcGuid = tbcEntry.id;
        }

        const sortedEntries = otherEntries.sort((a, b) => a.descriptionLong.localeCompare(b.descriptionLong));
        const finalSortedEntries = [...(noneEntry ? [noneEntry] : []), ...(tbcEntry ? [tbcEntry] : []), ...sortedEntries];

        this.approvedAdditionalUses = finalSortedEntries;
      }
    });
  }

  onSchemeChange(event: MatSelectChange): void {
    this.existingZoningControlsData.schemeId = event.value;
    this.loadZoningData();
    this.loadUsesPermittedOnSite();
    this.loadApprovedAdditionalUses();
    this.emitChanges();
  }

  onZoningChange(event: MatSelectChange): void {
    this.existingZoningControlsData.zoningId = event.value;
    this.loadPossibleUsesPermittedOnSite();
    this.loadPossibleAdditionalUses();
    this.emitChanges();
  }

  onUsesPermittedOnSiteChange(event: MatSelectChange): void {
    const selectedIds = event.value;

    if (selectedIds.includes(this.usesPermittedOnSiteNoneGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "None" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.existingZoningControlsData.usesPermittedOnSiteIds = [this.usesPermittedOnSiteNoneGuid];
    }
    else if (selectedIds.includes(this.usesPermittedOnSiteTbcGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "TBC" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.existingZoningControlsData.usesPermittedOnSiteIds = [this.usesPermittedOnSiteTbcGuid];
    }
    else {
      this.existingZoningControlsData.usesPermittedOnSiteIds = selectedIds.filter((id: string) => id !== this.usesPermittedOnSiteNoneGuid && id !== this.usesPermittedOnSiteTbcGuid);
    }

    this.emitChanges();
  }

  onApprovedAdditionalUsesChange(event: MatSelectChange): void {
    const selectedIds = event.value;

    if (selectedIds.includes(this.approvedAdditionalUsesNoneGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "None" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.existingZoningControlsData.approvedAdditionalUseIds = [this.approvedAdditionalUsesNoneGuid];
    }
    else if (selectedIds.includes(this.approvedAdditionalUsesTbcGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "TBC" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.existingZoningControlsData.approvedAdditionalUseIds = [this.approvedAdditionalUsesTbcGuid];
    }
    else {
      this.existingZoningControlsData.approvedAdditionalUseIds = selectedIds.filter((id: string) => id !== this.approvedAdditionalUsesNoneGuid && id !== this.approvedAdditionalUsesTbcGuid);
    }

    this.emitChanges();
  }

  emitChanges(): void {
    this.existingZoningControlsChange.emit(this.existingZoningControlsData);
  }
}

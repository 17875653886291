<main>
    <div class="heading-3 mb-3">
        Land Parcel {{ index + 1 | numberToWords }}
    </div>
    <div *ngIf="amountOfLandParcels > 1">
        <div class="heading-3 mt-3 mb-2">
            Area Available
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Metric Unit</label>
                <mat-select class="col-12 mt-1" [(ngModel)]="scope.metricUnit"
                    (selectionChange)="onMetricUnitChange($event.value)">
                    <mat-option *ngFor="let item of metricUnits" [value]="item.key">
                        {{ item.value }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Size of property (deeds extent to be on par with S.G. diagram)</label>
                <input class="col-12 mt-1" type="number" placeholder="0,1" [(ngModel)]="scope.propertySize"
                    (ngModelChange)="calculatePotentialUsableArea()" #propertySize="ngModel" name="propertySize" min="0"
                    step="0.1">
                <mat-error *ngIf="propertySize.invalid && (propertySize.dirty || propertySize.touched)">
                    <span *ngIf="propertySize.errors?.min">Value must be positive.</span>
                </mat-error>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Unusable area</label>
                <input class="col-12 mt-1" type="number" placeholder="100" [(ngModel)]="scope.unusableArea"
                    (ngModelChange)="calculatePotentialUsableArea()" #unusableArea="ngModel" name="unusableArea"
                    min="0">
                <mat-error *ngIf="unusableArea.invalid && (unusableArea.dirty || unusableArea.touched)">
                    <span *ngIf="unusableArea.errors?.min">Value must be positive.</span>
                </mat-error>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Potential usable area</label>
                <input class="col-12 mt-1 matrix-data" type="number" readonly [(ngModel)]="scope.potentialUsableArea"
                    (ngModelChange)="calculateValues()" #potentialUsableArea="ngModel" name="potentialUsableArea"
                    min="0" step="0.01" placeholder="0">
                <mat-error
                    *ngIf="potentialUsableArea.invalid && (potentialUsableArea.dirty || potentialUsableArea.touched)">
                    <span *ngIf="potentialUsableArea.errors?.min">Value must be positive.</span>
                </mat-error>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Proposed size of property</label>
                <input class="col-12 mt-1" type="number" placeholder="1,2" [(ngModel)]="scope.proposedPropertySize"
                    #proposedPropertySize="ngModel" name="proposedPropertySize" min="0">
                <mat-error
                    *ngIf="proposedPropertySize.invalid && (proposedPropertySize.dirty || proposedPropertySize.touched)">
                    <span *ngIf="proposedPropertySize.errors?.min">Value must be positive.</span>
                </mat-error>
            </div>
        </div>
    </div>
    <div class="heading-3 mt-3 mb-2">
        Projected Development Controls
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Open space per hectare of usable space required (%)</label>
            <input class="col-12 mt-1" type="number" placeholder="10" [(ngModel)]="scope.potentialOpenSpaceRequired"
                (ngModelChange)="calculateValues()" #potentialOpenSpace="ngModel" name="potentialOpenSpace" min="0">
            <mat-error *ngIf="potentialOpenSpace.invalid && (potentialOpenSpace.dirty || potentialOpenSpace.touched)">
                <span *ngIf="potentialOpenSpace.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Density/ha (units per ha)</label>
            <input class="col-12 mt-1" type="number" placeholder="80" [(ngModel)]="scope.potentialDensity"
                (ngModelChange)="calculateValues()" #density="ngModel" name="density" min="0">
            <mat-error *ngIf="density.invalid && (density.dirty || density.touched)">
                <span *ngIf="density.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">F.A.R.</label>
            <input class="col-12 mt-1" type="number" placeholder="1" [(ngModel)]="scope.potentialFloorAreaRatio"
                (ngModelChange)="calculateValues()" #far="ngModel" name="far" min="0">
            <mat-error *ngIf="far.invalid && (far.dirty || far.touched)">
                <span *ngIf="far.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Height (storeys)</label>
            <input class="col-12 mt-1" type="number" placeholder="2" [(ngModel)]="scope.potentialHeight"
                (ngModelChange)="calculateValues()" #height="ngModel" name="height" min="0">
            <mat-error *ngIf="height.invalid && (height.dirty || height.touched)">
                <span *ngIf="height.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Coverage (%)</label>
            <input class="col-12 mt-1" type="number" placeholder="50" [(ngModel)]="scope.potentialCoverage"
                (ngModelChange)="calculateValues()" #coverage="ngModel" name="coverage" min="0">
            <mat-error *ngIf="coverage.invalid && (coverage.dirty || coverage.touched)">
                <span *ngIf="coverage.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per unit (spaces per unit)</label>
            <input class="col-12 mt-1" type="number" placeholder="2" [(ngModel)]="scope.potentialParkingSpaces"
                (ngModelChange)="calculateValues()" #parkingSpaces="ngModel" name="parkingSpaces" min="0">
            <mat-error *ngIf="parkingSpaces.invalid && (parkingSpaces.dirty || parkingSpaces.touched)">
                <span *ngIf="parkingSpaces.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Visitors parking/unit (visitors parking per unit)</label>
            <input class="col-12 mt-1" type="number" placeholder="0,33"
                [(ngModel)]="scope.potentialVisitorsParkingSpaces" (ngModelChange)="calculateValues()"
                #visitorsParkingSpaces="ngModel" name="visitorsParkingSpaces" min="0">
            <mat-error
                *ngIf="visitorsParkingSpaces.invalid && (visitorsParkingSpaces.dirty || visitorsParkingSpaces.touched)">
                <span *ngIf="visitorsParkingSpaces.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per 100 m² (spaces per 100m²)</label>
            <input class="col-12 mt-1" type="number" placeholder="0"
                [(ngModel)]="scope.potentialParkingSpacesPerHundredSquareMeters" (ngModelChange)="calculateValues()"
                #parkingSpacesPerHundred="ngModel" name="parkingSpacesPerHundred" min="0">
            <mat-error
                *ngIf="parkingSpacesPerHundred.invalid && (parkingSpacesPerHundred.dirty || parkingSpacesPerHundred.touched)">
                <span *ngIf="parkingSpacesPerHundred.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="heading-3 mt-4 mb-2">
        Maximum Potential Development
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Open space per hectare of usable space required (ha)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="0,09" readonly
                [(ngModel)]="scope.potentialMaximumOpenSpaceRequired">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Maximum units calculated on usable space (units)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="72" readonly
                [(ngModel)]="scope.potentialMaximumUnitsOnUsableSpace">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Maximum m² based on FAR (m²)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="9000" readonly
                [(ngModel)]="scope.potentialMaximumSquareMetersBasedOnFloorAreaRatio">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Maximum m² based on coverage & height (m²)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="9000" readonly
                [(ngModel)]="scope.potentialMaximumSquareMetersBasedOnCoverageAndHeight">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Average size if maximum units are built (m²)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="125" readonly
                [(ngModel)]="scope.potentialMaximumUnitsAverageSize">
        </div>
    </div>
    <div class="heading-3 mt-4 mb-2">
        Space For Parking
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per unit (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="2" readonly
                [(ngModel)]="scope.potentialParkingSpacesWithMaxRights">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Visitors parking/unit (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="0.33" readonly
                [(ngModel)]="scope.potentialVisitorsParkingSpacesWithMaxRights">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per 100m² (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="34" readonly
                [(ngModel)]="scope.potentialParkingSpacesPerHundredSquareMetersWithMaxRights">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Total parking requirement for maximum development (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="168" readonly
                [(ngModel)]="scope.potentialTotalParkingSpacesWithMaxRights">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">
                Maximum parking spaces available on vacant land if fully developed (spaces)
            </label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="176" readonly
                [(ngModel)]="scope.potentialParkingSpacesAvailableOnVacantLandWithMaxRights">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <button class="button" (click)="calculateValues()">Refresh</button>
        </div>
    </div>
</main>
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AllBuildingsDoneWithApprovedBuildingPlansDto } from '../models/all-buildings-done-with-approved-building-plans-dto';
import { AskingPriceConfidenceReasonDto } from '../models/asking-price-confidence-reason-dto';
import { CopiesOfApprovedBuildingPlansDto } from '../models/copies-of-approved-building-plans-dto';
import { DevelopmentSpecialtyDto } from '../models/development-specialty-dto';
import { EstateAgentStatusDto } from '../models/estate-agent-status-dto';
import { FileTypeDto } from '../models/file-type-dto';
import { InvoiceIssuesToDto } from '../models/invoice-issues-to-dto';
import { LandDevelopmentTypeDto } from '../models/land-development-type-dto';
import { LandUseDto } from '../models/land-use-dto';
import { MainPropertyUseDto } from '../models/main-property-use-dto';
import { MetroDto } from '../models/metro-dto';
import { MunicipalCouncilDto } from '../models/municipal-council-dto';
import { ProfessionDto } from '../models/profession-dto';
import { PropertySectorDto } from '../models/property-sector-dto';
import { PropertyTypeDto } from '../models/property-type-dto';
import { ReasonForNotInterestedDto } from '../models/reason-for-not-interested-dto';
import { RoleDto } from '../models/role-dto';
import { SacplanRegistrationTypeDto } from '../models/sacplan-registration-type-dto';
import { SchemeDto } from '../models/scheme-dto';
import { SellingMyPropertyPreferredOptionDto } from '../models/selling-my-property-preferred-option-dto';
import { UseRightTypeDto } from '../models/use-right-type-dto';
import { WishToSellDto } from '../models/wish-to-sell-dto';
import { ZoningDto } from '../models/zoning-dto';

@Injectable({
  providedIn: 'root',
})
export class LookupService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1LookupGetWishToSellOptionsGet
   */
  static readonly ApiV1LookupGetWishToSellOptionsGetPath = '/api/v1/Lookup/GetWishToSellOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetWishToSellOptionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetWishToSellOptionsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<WishToSellDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetWishToSellOptionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<WishToSellDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetWishToSellOptionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetWishToSellOptionsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<WishToSellDto>> {

    return this.apiV1LookupGetWishToSellOptionsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<WishToSellDto>>) => r.body as Array<WishToSellDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetJointOwnershipTypeOptionsGet
   */
  static readonly ApiV1LookupGetJointOwnershipTypeOptionsGetPath = '/api/v1/Lookup/GetJointOwnershipTypeOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetJointOwnershipTypeOptionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetJointOwnershipTypeOptionsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AllBuildingsDoneWithApprovedBuildingPlansDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetJointOwnershipTypeOptionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AllBuildingsDoneWithApprovedBuildingPlansDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetJointOwnershipTypeOptionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetJointOwnershipTypeOptionsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AllBuildingsDoneWithApprovedBuildingPlansDto>> {

    return this.apiV1LookupGetJointOwnershipTypeOptionsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AllBuildingsDoneWithApprovedBuildingPlansDto>>) => r.body as Array<AllBuildingsDoneWithApprovedBuildingPlansDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetAskingPriceConfidenceReasonsGet
   */
  static readonly ApiV1LookupGetAskingPriceConfidenceReasonsGetPath = '/api/v1/Lookup/GetAskingPriceConfidenceReasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetAskingPriceConfidenceReasonsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAskingPriceConfidenceReasonsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AskingPriceConfidenceReasonDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetAskingPriceConfidenceReasonsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AskingPriceConfidenceReasonDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetAskingPriceConfidenceReasonsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAskingPriceConfidenceReasonsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AskingPriceConfidenceReasonDto>> {

    return this.apiV1LookupGetAskingPriceConfidenceReasonsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AskingPriceConfidenceReasonDto>>) => r.body as Array<AskingPriceConfidenceReasonDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetInvoiceIssuesLookupValuesGet
   */
  static readonly ApiV1LookupGetInvoiceIssuesLookupValuesGetPath = '/api/v1/Lookup/GetInvoiceIssuesLookupValues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetInvoiceIssuesLookupValuesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetInvoiceIssuesLookupValuesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<InvoiceIssuesToDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetInvoiceIssuesLookupValuesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<InvoiceIssuesToDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetInvoiceIssuesLookupValuesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetInvoiceIssuesLookupValuesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<InvoiceIssuesToDto>> {

    return this.apiV1LookupGetInvoiceIssuesLookupValuesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<InvoiceIssuesToDto>>) => r.body as Array<InvoiceIssuesToDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetReasonForNotInterestedLookupValuesGet
   */
  static readonly ApiV1LookupGetReasonForNotInterestedLookupValuesGetPath = '/api/v1/Lookup/GetReasonForNotInterestedLookupValues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetReasonForNotInterestedLookupValuesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetReasonForNotInterestedLookupValuesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<ReasonForNotInterestedDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetReasonForNotInterestedLookupValuesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<ReasonForNotInterestedDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetReasonForNotInterestedLookupValuesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetReasonForNotInterestedLookupValuesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<ReasonForNotInterestedDto>> {

    return this.apiV1LookupGetReasonForNotInterestedLookupValuesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<ReasonForNotInterestedDto>>) => r.body as Array<ReasonForNotInterestedDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetSellingMyPropertyPreferredOptionGet
   */
  static readonly ApiV1LookupGetSellingMyPropertyPreferredOptionGetPath = '/api/v1/Lookup/GetSellingMyPropertyPreferredOption';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetSellingMyPropertyPreferredOptionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSellingMyPropertyPreferredOptionGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<SellingMyPropertyPreferredOptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetSellingMyPropertyPreferredOptionGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<SellingMyPropertyPreferredOptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetSellingMyPropertyPreferredOptionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSellingMyPropertyPreferredOptionGet(params?: {
    context?: HttpContext
  }
): Observable<Array<SellingMyPropertyPreferredOptionDto>> {

    return this.apiV1LookupGetSellingMyPropertyPreferredOptionGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<SellingMyPropertyPreferredOptionDto>>) => r.body as Array<SellingMyPropertyPreferredOptionDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetGetMainPropertyGet
   */
  static readonly ApiV1LookupGetGetMainPropertyGetPath = '/api/v1/Lookup/GetGetMainProperty';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetGetMainPropertyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetGetMainPropertyGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetGetMainPropertyGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetGetMainPropertyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetGetMainPropertyGet(params?: {
    context?: HttpContext
  }
): Observable<Array<MainPropertyUseDto>> {

    return this.apiV1LookupGetGetMainPropertyGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>) => r.body as Array<MainPropertyUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetApproximateBuildingAgeGet
   */
  static readonly ApiV1LookupGetApproximateBuildingAgeGetPath = '/api/v1/Lookup/GetApproximateBuildingAge';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetApproximateBuildingAgeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetApproximateBuildingAgeGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetApproximateBuildingAgeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetApproximateBuildingAgeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetApproximateBuildingAgeGet(params?: {
    context?: HttpContext
  }
): Observable<Array<MainPropertyUseDto>> {

    return this.apiV1LookupGetApproximateBuildingAgeGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>) => r.body as Array<MainPropertyUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetOccupationStatusGet
   */
  static readonly ApiV1LookupGetOccupationStatusGetPath = '/api/v1/Lookup/GetOccupationStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetOccupationStatusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetOccupationStatusGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetOccupationStatusGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetOccupationStatusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetOccupationStatusGet(params?: {
    context?: HttpContext
  }
): Observable<Array<MainPropertyUseDto>> {

    return this.apiV1LookupGetOccupationStatusGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>) => r.body as Array<MainPropertyUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetPropertyTypesGet
   */
  static readonly ApiV1LookupGetPropertyTypesGetPath = '/api/v1/Lookup/GetPropertyTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetPropertyTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetPropertyTypesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<PropertyTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetPropertyTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<PropertyTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetPropertyTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetPropertyTypesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<PropertyTypeDto>> {

    return this.apiV1LookupGetPropertyTypesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<PropertyTypeDto>>) => r.body as Array<PropertyTypeDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetPropertyOwnershipTypeGet
   */
  static readonly ApiV1LookupGetPropertyOwnershipTypeGetPath = '/api/v1/Lookup/GetPropertyOwnershipType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetPropertyOwnershipTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetPropertyOwnershipTypeGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetPropertyOwnershipTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetPropertyOwnershipTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetPropertyOwnershipTypeGet(params?: {
    context?: HttpContext
  }
): Observable<Array<MainPropertyUseDto>> {

    return this.apiV1LookupGetPropertyOwnershipTypeGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>) => r.body as Array<MainPropertyUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetMaritalStatusGet
   */
  static readonly ApiV1LookupGetMaritalStatusGetPath = '/api/v1/Lookup/GetMaritalStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetMaritalStatusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetMaritalStatusGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetMaritalStatusGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetMaritalStatusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetMaritalStatusGet(params?: {
    context?: HttpContext
  }
): Observable<Array<MainPropertyUseDto>> {

    return this.apiV1LookupGetMaritalStatusGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<MainPropertyUseDto>>) => r.body as Array<MainPropertyUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetFileTypesGet
   */
  static readonly ApiV1LookupGetFileTypesGetPath = '/api/v1/Lookup/GetFileTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetFileTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetFileTypesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<FileTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetFileTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<FileTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetFileTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetFileTypesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<FileTypeDto>> {

    return this.apiV1LookupGetFileTypesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<FileTypeDto>>) => r.body as Array<FileTypeDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetCopiesOfApprovedBuildingPlansGet
   */
  static readonly ApiV1LookupGetCopiesOfApprovedBuildingPlansGetPath = '/api/v1/Lookup/GetCopiesOfApprovedBuildingPlans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetCopiesOfApprovedBuildingPlansGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetCopiesOfApprovedBuildingPlansGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<CopiesOfApprovedBuildingPlansDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetCopiesOfApprovedBuildingPlansGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<CopiesOfApprovedBuildingPlansDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetCopiesOfApprovedBuildingPlansGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetCopiesOfApprovedBuildingPlansGet(params?: {
    context?: HttpContext
  }
): Observable<Array<CopiesOfApprovedBuildingPlansDto>> {

    return this.apiV1LookupGetCopiesOfApprovedBuildingPlansGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<CopiesOfApprovedBuildingPlansDto>>) => r.body as Array<CopiesOfApprovedBuildingPlansDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetAllBuildingsDoneWithApprovedBuildingPlansGet
   */
  static readonly ApiV1LookupGetAllBuildingsDoneWithApprovedBuildingPlansGetPath = '/api/v1/Lookup/GetAllBuildingsDoneWithApprovedBuildingPlans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetAllBuildingsDoneWithApprovedBuildingPlansGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAllBuildingsDoneWithApprovedBuildingPlansGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AllBuildingsDoneWithApprovedBuildingPlansDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetAllBuildingsDoneWithApprovedBuildingPlansGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AllBuildingsDoneWithApprovedBuildingPlansDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetAllBuildingsDoneWithApprovedBuildingPlansGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAllBuildingsDoneWithApprovedBuildingPlansGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AllBuildingsDoneWithApprovedBuildingPlansDto>> {

    return this.apiV1LookupGetAllBuildingsDoneWithApprovedBuildingPlansGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AllBuildingsDoneWithApprovedBuildingPlansDto>>) => r.body as Array<AllBuildingsDoneWithApprovedBuildingPlansDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetLandUseDataGet
   */
  static readonly ApiV1LookupGetLandUseDataGetPath = '/api/v1/Lookup/GetLandUseData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetLandUseDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandUseDataGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetLandUseDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetLandUseDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandUseDataGet(params?: {
    context?: HttpContext
  }
): Observable<Array<LandUseDto>> {

    return this.apiV1LookupGetLandUseDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>) => r.body as Array<LandUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetSpecialLandUsesGet
   */
  static readonly ApiV1LookupGetSpecialLandUsesGetPath = '/api/v1/Lookup/GetSpecialLandUses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetSpecialLandUsesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSpecialLandUsesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetSpecialLandUsesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetSpecialLandUsesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSpecialLandUsesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<LandUseDto>> {

    return this.apiV1LookupGetSpecialLandUsesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>) => r.body as Array<LandUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetPredominantLandUseDataGet
   */
  static readonly ApiV1LookupGetPredominantLandUseDataGetPath = '/api/v1/Lookup/GetPredominantLandUseData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetPredominantLandUseDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetPredominantLandUseDataGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetPredominantLandUseDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetPredominantLandUseDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetPredominantLandUseDataGet(params?: {
    context?: HttpContext
  }
): Observable<Array<LandUseDto>> {

    return this.apiV1LookupGetPredominantLandUseDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>) => r.body as Array<LandUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetAdditionalLandUseDataGet
   */
  static readonly ApiV1LookupGetAdditionalLandUseDataGetPath = '/api/v1/Lookup/GetAdditionalLandUseData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetAdditionalLandUseDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAdditionalLandUseDataGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetAdditionalLandUseDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetAdditionalLandUseDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAdditionalLandUseDataGet(params?: {
    context?: HttpContext
  }
): Observable<Array<LandUseDto>> {

    return this.apiV1LookupGetAdditionalLandUseDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>) => r.body as Array<LandUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetSelectedSchemeGet
   */
  static readonly ApiV1LookupGetSelectedSchemeGetPath = '/api/v1/Lookup/GetSelectedScheme';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetSelectedSchemeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSelectedSchemeGet$Response(params?: {
    metroDescription?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<SchemeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetSelectedSchemeGetPath, 'get');
    if (params) {
      rb.query('metroDescription', params.metroDescription, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<SchemeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetSelectedSchemeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSelectedSchemeGet(params?: {
    metroDescription?: string;
    context?: HttpContext
  }
): Observable<Array<SchemeDto>> {

    return this.apiV1LookupGetSelectedSchemeGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<SchemeDto>>) => r.body as Array<SchemeDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetSchemeDataGet
   */
  static readonly ApiV1LookupGetSchemeDataGetPath = '/api/v1/Lookup/GetSchemeData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetSchemeDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSchemeDataGet$Response(params?: {
    metroDescription?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<SchemeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetSchemeDataGetPath, 'get');
    if (params) {
      rb.query('metroDescription', params.metroDescription, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<SchemeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetSchemeDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSchemeDataGet(params?: {
    metroDescription?: string;
    context?: HttpContext
  }
): Observable<Array<SchemeDto>> {

    return this.apiV1LookupGetSchemeDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<SchemeDto>>) => r.body as Array<SchemeDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetAllSchemesGet
   */
  static readonly ApiV1LookupGetAllSchemesGetPath = '/api/v1/Lookup/GetAllSchemes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetAllSchemesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAllSchemesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<SchemeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetAllSchemesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<SchemeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetAllSchemesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAllSchemesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<SchemeDto>> {

    return this.apiV1LookupGetAllSchemesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<SchemeDto>>) => r.body as Array<SchemeDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetZoningDataGet
   */
  static readonly ApiV1LookupGetZoningDataGetPath = '/api/v1/Lookup/GetZoningData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetZoningDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetZoningDataGet$Response(params?: {
    schemeId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<ZoningDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetZoningDataGetPath, 'get');
    if (params) {
      rb.query('schemeId', params.schemeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<ZoningDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetZoningDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetZoningDataGet(params?: {
    schemeId?: string;
    context?: HttpContext
  }
): Observable<Array<ZoningDto>> {

    return this.apiV1LookupGetZoningDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<ZoningDto>>) => r.body as Array<ZoningDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetUseRightTypesGet
   */
  static readonly ApiV1LookupGetUseRightTypesGetPath = '/api/v1/Lookup/GetUseRightTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetUseRightTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetUseRightTypesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UseRightTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetUseRightTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UseRightTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetUseRightTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetUseRightTypesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<UseRightTypeDto>> {

    return this.apiV1LookupGetUseRightTypesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UseRightTypeDto>>) => r.body as Array<UseRightTypeDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetLandUsesBySchemeZoningUseRightGet
   */
  static readonly ApiV1LookupGetLandUsesBySchemeZoningUseRightGetPath = '/api/v1/Lookup/GetLandUsesBySchemeZoningUseRight';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetLandUsesBySchemeZoningUseRightGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandUsesBySchemeZoningUseRightGet$Response(params?: {
    schemeId?: string;
    zoningId?: string;
    useRightTypeId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetLandUsesBySchemeZoningUseRightGetPath, 'get');
    if (params) {
      rb.query('schemeId', params.schemeId, {});
      rb.query('zoningId', params.zoningId, {});
      rb.query('useRightTypeId', params.useRightTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetLandUsesBySchemeZoningUseRightGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandUsesBySchemeZoningUseRightGet(params?: {
    schemeId?: string;
    zoningId?: string;
    useRightTypeId?: string;
    context?: HttpContext
  }
): Observable<Array<LandUseDto>> {

    return this.apiV1LookupGetLandUsesBySchemeZoningUseRightGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>) => r.body as Array<LandUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetLandUsesBySchemeUseRightGet
   */
  static readonly ApiV1LookupGetLandUsesBySchemeUseRightGetPath = '/api/v1/Lookup/GetLandUsesBySchemeUseRight';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetLandUsesBySchemeUseRightGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandUsesBySchemeUseRightGet$Response(params?: {
    schemeId?: string;
    useRightTypeId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetLandUsesBySchemeUseRightGetPath, 'get');
    if (params) {
      rb.query('schemeId', params.schemeId, {});
      rb.query('useRightTypeId', params.useRightTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetLandUsesBySchemeUseRightGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandUsesBySchemeUseRightGet(params?: {
    schemeId?: string;
    useRightTypeId?: string;
    context?: HttpContext
  }
): Observable<Array<LandUseDto>> {

    return this.apiV1LookupGetLandUsesBySchemeUseRightGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>) => r.body as Array<LandUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetLandUsesBySchemeGet
   */
  static readonly ApiV1LookupGetLandUsesBySchemeGetPath = '/api/v1/Lookup/GetLandUsesByScheme';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetLandUsesBySchemeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandUsesBySchemeGet$Response(params?: {
    schemeId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetLandUsesBySchemeGetPath, 'get');
    if (params) {
      rb.query('schemeId', params.schemeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetLandUsesBySchemeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandUsesBySchemeGet(params?: {
    schemeId?: string;
    context?: HttpContext
  }
): Observable<Array<LandUseDto>> {

    return this.apiV1LookupGetLandUsesBySchemeGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<LandUseDto>>) => r.body as Array<LandUseDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetSacplanRegistrationTypesGet
   */
  static readonly ApiV1LookupGetSacplanRegistrationTypesGetPath = '/api/v1/Lookup/GetSacplanRegistrationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetSacplanRegistrationTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSacplanRegistrationTypesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<SacplanRegistrationTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetSacplanRegistrationTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<SacplanRegistrationTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetSacplanRegistrationTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetSacplanRegistrationTypesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<SacplanRegistrationTypeDto>> {

    return this.apiV1LookupGetSacplanRegistrationTypesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<SacplanRegistrationTypeDto>>) => r.body as Array<SacplanRegistrationTypeDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetMunicipalCouncilsGet
   */
  static readonly ApiV1LookupGetMunicipalCouncilsGetPath = '/api/v1/Lookup/GetMunicipalCouncils';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetMunicipalCouncilsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetMunicipalCouncilsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<MunicipalCouncilDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetMunicipalCouncilsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<MunicipalCouncilDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetMunicipalCouncilsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetMunicipalCouncilsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<MunicipalCouncilDto>> {

    return this.apiV1LookupGetMunicipalCouncilsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<MunicipalCouncilDto>>) => r.body as Array<MunicipalCouncilDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetLandDevelopmentTypesGet
   */
  static readonly ApiV1LookupGetLandDevelopmentTypesGetPath = '/api/v1/Lookup/GetLandDevelopmentTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetLandDevelopmentTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandDevelopmentTypesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<LandDevelopmentTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetLandDevelopmentTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<LandDevelopmentTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetLandDevelopmentTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetLandDevelopmentTypesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<LandDevelopmentTypeDto>> {

    return this.apiV1LookupGetLandDevelopmentTypesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<LandDevelopmentTypeDto>>) => r.body as Array<LandDevelopmentTypeDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetDevelopmentSpecialtiesGet
   */
  static readonly ApiV1LookupGetDevelopmentSpecialtiesGetPath = '/api/v1/Lookup/GetDevelopmentSpecialties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetDevelopmentSpecialtiesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetDevelopmentSpecialtiesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<DevelopmentSpecialtyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetDevelopmentSpecialtiesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<DevelopmentSpecialtyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetDevelopmentSpecialtiesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetDevelopmentSpecialtiesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<DevelopmentSpecialtyDto>> {

    return this.apiV1LookupGetDevelopmentSpecialtiesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<DevelopmentSpecialtyDto>>) => r.body as Array<DevelopmentSpecialtyDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetProfessionsGet
   */
  static readonly ApiV1LookupGetProfessionsGetPath = '/api/v1/Lookup/GetProfessions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetProfessionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetProfessionsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<ProfessionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetProfessionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<ProfessionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetProfessionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetProfessionsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<ProfessionDto>> {

    return this.apiV1LookupGetProfessionsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<ProfessionDto>>) => r.body as Array<ProfessionDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetEstateAgentStatusesGet
   */
  static readonly ApiV1LookupGetEstateAgentStatusesGetPath = '/api/v1/Lookup/GetEstateAgentStatuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetEstateAgentStatusesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetEstateAgentStatusesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<EstateAgentStatusDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetEstateAgentStatusesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<EstateAgentStatusDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetEstateAgentStatusesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetEstateAgentStatusesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<EstateAgentStatusDto>> {

    return this.apiV1LookupGetEstateAgentStatusesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<EstateAgentStatusDto>>) => r.body as Array<EstateAgentStatusDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetPropertySectorsGet
   */
  static readonly ApiV1LookupGetPropertySectorsGetPath = '/api/v1/Lookup/GetPropertySectors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetPropertySectorsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetPropertySectorsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<PropertySectorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetPropertySectorsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<PropertySectorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetPropertySectorsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetPropertySectorsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<PropertySectorDto>> {

    return this.apiV1LookupGetPropertySectorsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<PropertySectorDto>>) => r.body as Array<PropertySectorDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetUserRolesGet
   */
  static readonly ApiV1LookupGetUserRolesGetPath = '/api/v1/Lookup/GetUserRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetUserRolesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetUserRolesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<RoleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetUserRolesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<RoleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetUserRolesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetUserRolesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<RoleDto>> {

    return this.apiV1LookupGetUserRolesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<RoleDto>>) => r.body as Array<RoleDto>)
    );
  }

  /**
   * Path part for operation apiV1LookupGetAllMetroItemsGet
   */
  static readonly ApiV1LookupGetAllMetroItemsGetPath = '/api/v1/Lookup/GetAllMetroItems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupGetAllMetroItemsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAllMetroItemsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<MetroDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupGetAllMetroItemsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<MetroDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupGetAllMetroItemsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupGetAllMetroItemsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<MetroDto>> {

    return this.apiV1LookupGetAllMetroItemsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<MetroDto>>) => r.body as Array<MetroDto>)
    );
  }

}

import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { LookupService, PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { ExistingLandUseDto, LandUseDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-existing-land-use',
  templateUrl: './existing-land-use.component.html',
  styleUrls: ['./existing-land-use.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ExistingLandUseComponent implements OnInit {

  @Input() planningReportId: string;
  @Output() existingLandUseChange = new EventEmitter<ExistingLandUseDto>();

  existingLandUseData: ExistingLandUseDto = {
    predominantLandUseId: "",
    additionalLandUseIds: [],
    surveyorGeneralErfExtent: 0,
    buildingFootprintEstimate: 0,
    grossFloorAreaEstimate: 0,
    coverageEstimate: 0,
    heightEstimateHighestBuilding: 0,
  };
  predominantLandUseData: LandUseDto[];
  additionalLandUseData: LandUseDto[];

  tbcGuid: string | null = null;
  noneGuid: string | null = null;

  constructor(
    private snackBar: MatSnackBar,
    private lookupService: LookupService,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadExistingLandUse();
    this.loadSpecialLandUses();
  }

  async loadExistingLandUse(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetExistingLandUseGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response: ExistingLandUseDto) => {
        this.existingLandUseData = response;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load existing land use data.');
      }
    });
  }

  async loadSpecialLandUses(): Promise<void> {
    this.lookupService.apiV1LookupGetSpecialLandUsesGet().subscribe({
      next: (response: LandUseDto[]) => {
        const noneEntry = response.find(item => item.descriptionShort === 'None');
        const tbcEntry = response.find(item => item.descriptionShort === 'TBC');
        const otherEntries = response.filter(item => item.descriptionShort !== 'None' && item.descriptionShort !== 'TBC');

        if (noneEntry) {
          this.noneGuid = noneEntry.id;
        }
        if (tbcEntry) {
          this.tbcGuid = tbcEntry.id;
        }

        const sortedEntries = otherEntries.sort((a, b) => a.descriptionShort.localeCompare(b.descriptionShort));
        const finalSortedEntries = [...(noneEntry ? [noneEntry] : []), ...(tbcEntry ? [tbcEntry] : []), ...sortedEntries];

        this.predominantLandUseData = finalSortedEntries;
        this.additionalLandUseData = finalSortedEntries;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load predominant and additional land use data.');
      }
    });
  }

  onPredominantLandUseChange(event: MatSelectChange): void {
    this.existingLandUseData.predominantLandUseId = event.value;
    this.emitChanges();
  }

  onAdditionalLandUsesChange(event: MatSelectChange): void {
    const selectedIds = event.value;

    if (selectedIds.includes(this.noneGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "None" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.existingLandUseData.additionalLandUseIds = [this.noneGuid];
    }
    else if (selectedIds.includes(this.tbcGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "TBC" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.existingLandUseData.additionalLandUseIds = [this.tbcGuid];
    }
    else {
      this.existingLandUseData.additionalLandUseIds = selectedIds.filter((id: any) => id !== this.noneGuid && id !== this.tbcGuid);
    }

    this.emitChanges();
  }

  emitChanges(): void {
    this.existingLandUseChange.emit(this.existingLandUseData);
  }
}

<main>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="paragraph" [style.width.%]="column.width"
                    [ngClass]="column.headerAlign ? 'header-align-' + column.headerAlign : ''">
                    <span class="column-header">{{ column.header }}</span>
                    <button *ngIf="column.headerButton" mat-icon-button (click)="column.headerButton.action()"
                        class="header-button" [ngStyle]="{'background-color': column.headerButton.color || 'coral'}">
                        <mat-icon class="material-icons-outlined">{{ column.headerButton.icon }}</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element" class="paragraph"
                    [ngClass]="column.cellAlign ? 'cell-align-' + column.cellAlign : ''" [style.width.%]="column.width">
                    <ng-container *ngIf="column.isBoolean; else textCell">
                        <mat-slide-toggle color="primary" [(ngModel)]="element[column.columnDef]"></mat-slide-toggle>
                    </ng-container>
                    <ng-container *ngIf="column.isAction">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <ng-container *ngFor="let option of actionOptions">
                                <button mat-menu-item (click)="option.action(element)">{{ option.label }}</button>
                            </ng-container>
                        </mat-menu>
                    </ng-container>
                    <ng-template #textCell>{{ element[column.columnDef] }}</ng-template>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
    </div>
</main>
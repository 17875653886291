<main>
    <div class="heading-3 mt-3 mb-2">
        Development Controls
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Open space per hectare of usable space required (%)</label>
            <input class="col-12 mt-1" type="number" placeholder="10" (ngModelChange)="calculateValues()"
                [(ngModel)]="currentConstructionScopeData.currentOpenSpaceRequired" #openSpace="ngModel"
                name="openSpace" min="0" max="100" step="0.01"
                [value]="currentConstructionScopeData.currentOpenSpaceRequired | number:'1.2-2'">
            <mat-error *ngIf="openSpace.invalid && (openSpace.dirty || openSpace.touched)">
                <span *ngIf="openSpace.errors?.min">Value must be positive.</span>
                <span *ngIf="openSpace.errors?.max">Value must be less than or equal to 100.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Density/ha (units per ha)</label>
            <input class="col-12 mt-1" type="number" placeholder="80" (ngModelChange)="calculateValues()"
                [(ngModel)]="currentConstructionScopeData.currentDensity" #density="ngModel" name="density" min="0.00"
                max="100" step="0.01" [value]="currentConstructionScopeData.currentDensity | number:'1.2-2'">
            <mat-error *ngIf="density.invalid && (density.dirty || density.touched)">
                <span *ngIf="density.errors?.min">Value must be positive.</span>
                <span *ngIf="openSpace.errors?.max">Value must be less than or equal to 100.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">F.A.R.</label>
            <input class="col-12 mt-1" type="number" placeholder="1" (ngModelChange)="calculateValues()"
                [(ngModel)]="currentConstructionScopeData.currentFloorAreaRatio" #far="ngModel" name="far" min="0"
                step="0.01" max="100" [value]="currentConstructionScopeData.currentFloorAreaRatio | number:'1.2-2'">
            <mat-error *ngIf="far.invalid && (far.dirty || far.touched)">
                <span *ngIf="far.errors?.min">Value must be positive.</span>
                <span *ngIf="far.errors?.max">Value must be less than or equal to 100.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Height (storeys)</label>
            <input class="col-12 mt-1" type="number" placeholder="2" (ngModelChange)="calculateValues()"
                [(ngModel)]="currentConstructionScopeData.currentHeight" #height="ngModel" name="height" min="0"
                step="0.01" max="100">
            <mat-error *ngIf="height.invalid && (height.dirty || height.touched)">
                <span *ngIf="height.errors?.min">Value must be positive.</span>
                <span *ngIf="height.errors?.max">Value must be less than or equal to 100.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Coverage (%)</label>
            <input class="col-12 mt-1" type="number" placeholder="50" (ngModelChange)="calculateValues()"
                [(ngModel)]="currentConstructionScopeData.currentCoverage" #coverage="ngModel" name="coverage" min="0"
                step="0.01" max="100" [value]="currentConstructionScopeData.currentCoverage | number:'1.2-2'">
            <mat-error *ngIf="coverage.invalid && (coverage.dirty || coverage.touched)">
                <span *ngIf="coverage.errors?.min">Value must be positive.</span>
                <span *ngIf="coverage.errors?.max">Value must be less than or equal to 100.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per unit (spaces per unit)</label>
            <input class="col-12 mt-1" type="number" placeholder="2" (ngModelChange)="calculateValues()"
                [(ngModel)]="currentConstructionScopeData.currentParkingSpaces" #parkingSpaces="ngModel"
                name="parkingSpaces" min="0" step="0.01" max="100"
                [value]="currentConstructionScopeData.currentParkingSpaces | number:'1.2-2'">
            <mat-error *ngIf="parkingSpaces.invalid && (parkingSpaces.dirty || parkingSpaces.touched)">
                <span *ngIf="parkingSpaces.errors?.min">Value must be positive.</span>
                <span *ngIf="parkingSpaces.errors?.max">Value must be less than or equal to 100.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Visitors parking/unit (visitors parking per unit)</label>
            <input class="col-12 mt-1" type="number" placeholder="0,33" (ngModelChange)="calculateValues()"
                [(ngModel)]="currentConstructionScopeData.currentVisitorsParkingSpaces" #visitorsParkingSpaces="ngModel"
                name="visitorsParkingSpaces" min="0" step="0.01" max="100"
                [value]="currentConstructionScopeData.currentVisitorsParkingSpaces | number:'1.2-2'">
            <mat-error
                *ngIf="visitorsParkingSpaces.invalid && (visitorsParkingSpaces.dirty || visitorsParkingSpaces.touched)">
                <span *ngIf="visitorsParkingSpaces.errors?.min">Value must be positive.</span>
                <span *ngIf="visitorsParkingSpaces.errors?.max">Value must be less than or equal to 100.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per 100 m² (spaces per 100m²)</label>
            <input class="col-12 mt-1" type="number" placeholder="0" (ngModelChange)="calculateValues()"
                [(ngModel)]="currentConstructionScopeData.currentParkingSpacesPerHundredSquareMeters"
                #parkingSpacesPerHundred="ngModel" name="parkingSpacesPerHundred" min="0" step="0.01" max="100">
            <mat-error
                *ngIf="parkingSpacesPerHundred.invalid && (parkingSpacesPerHundred.dirty || parkingSpacesPerHundred.touched)">
                <span *ngIf="parkingSpacesPerHundred.errors?.min">Value must be positive.</span>
                <span *ngIf="parkingSpacesPerHundred.errors?.max">Value must be less than or equal to 100.</span>
            </mat-error>
        </div>
    </div>
    <div class="heading-3 mt-4 mb-2">
        Maximum Potential Development with Existing Rights
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Open space per hectare of usable space required (ha)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="0,09" readonly
                [(ngModel)]="currentConstructionScopeData.currentMaximumOpenSpaceRequired" min="0" step="0.01"
                [value]="currentConstructionScopeData.currentMaximumOpenSpaceRequired | number:'1.2-2'">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Maximum units calculated on usable space (units)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="72" readonly
                [(ngModel)]="currentConstructionScopeData.currentMaximumUnitsOnUsableSpace">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Maximum m² based on FAR (m²)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="9000" readonly
                [(ngModel)]="currentConstructionScopeData.currentMaximumSquareMetersBasedOnFloorAreaRatio">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Maximum m² based on coverage & height (m²)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="9000" readonly
                [(ngModel)]="currentConstructionScopeData.currentMaximumSquareMetersBasedOnCoverageAndHeight">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Average size if maximum units are built (m²)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="125" readonly
                [(ngModel)]="currentConstructionScopeData.currentMaximumUnitsAverageSize">
        </div>
    </div>
    <div class="heading-3 mt-4 mb-2">
        Space For Parking When Implementing Existing Max. Rights
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per unit (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="2" readonly
                [(ngModel)]="currentConstructionScopeData.currentParkingSpacesWithMaxRights">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Visitors parking/unit (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="0.33" readonly
                [(ngModel)]="currentConstructionScopeData.currentVisitorsParkingSpacesWithMaxRights" min="0" step="0.01"
                max="100" [value]="currentConstructionScopeData.currentParkingSpacesWithMaxRights | number:'1.2-2'">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per 100m² (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="34" readonly
                [(ngModel)]="currentConstructionScopeData.currentParkingSpacesPerHundredSquareMetersWithMaxRights">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Total parking requirement for maximum development (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="168" readonly
                [(ngModel)]="currentConstructionScopeData.currentTotalParkingSpacesWithMaxRights" min="0" step="0.01"
                   [value]="currentConstructionScopeData.currentTotalParkingSpacesWithMaxRights | number:'1.2-2'">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">
                Maximum parking spaces available on vacant land if fully developed (spaces)
            </label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="17" readonly
                [(ngModel)]="currentConstructionScopeData.currentParkingSpacesAvailableOnVacantLandWithMaxRights">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <button class="button" (click)="calculateValues()">Refresh</button>
        </div>
    </div>
</main>

<main>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Predominant land use</label>
            <mat-select class="col-12 mt-1" [(value)]="existingLandUseData.predominantLandUseId"
                (selectionChange)="onPredominantLandUseChange($event)">
                <mat-option *ngFor="let landUse of predominantLandUseData" [value]="landUse.id">
                    {{ landUse.descriptionShort }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Additional land use(s)</label>
            <mat-select class="col-12 mt-1" [(value)]="existingLandUseData.additionalLandUseIds"
                (selectionChange)="onAdditionalLandUsesChange($event)" multiple>
                <mat-option *ngFor="let landUse of additionalLandUseData" [value]="landUse.id">
                    {{ landUse.descriptionShort }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Surveyor General erf extent (m²)</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="existingLandUseData.surveyorGeneralErfExtent" placeholder="0">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Building footprint estimate (m²)</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="existingLandUseData.buildingFootprintEstimate" placeholder="0">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Gross floor area estimate</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="existingLandUseData.grossFloorAreaEstimate" placeholder="0">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Coverage estimate</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="existingLandUseData.coverageEstimate" placeholder="0">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Height estimate: highest building</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="existingLandUseData.heightEstimateHighestBuilding" placeholder="0">
        </div>
    </div>
</main>
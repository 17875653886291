<main class="main-container">
    <form [formGroup]="urbanPlannerInfoForm" (ngSubmit)="onSubmit()">
        <div class="content-padding">
            <div class="row paragraph mt-3">
                <div class="col-12 heading-2">
                    Step 1: Personal And Company Information
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div class="col-10">
                    <span class="burnt-red bold">|</span>
                    Which year did you graduate as a planner?
                </div>
                <div class="col-10">
                    <input class="mat-input-section" id="graduationYear" formControlName="graduationYear" type="number"
                        placeholder="graduationYear">
                    <small class="burnt-red" *ngIf="
                        urbanPlannerInfoForm.get('graduationYear').invalid &&
                        urbanPlannerInfoForm.get('graduationYear').touched">
                        * Invalid graduation year.
                    </small>
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div class="col-10">
                    <span class="burnt-red bold">|</span>
                    From which institution did you graduate?
                </div>
                <div class="col-10">
                    <input class="mat-input-section" id="graduationInstitution" placeholder="graduationInstitution"
                        formControlName="graduationInstitution" type="text" maxlength="250">
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div class="col-10">
                    <span class="burnt-red bold">|</span>
                    How many years of experience do you have in the field of land use management (statutory planning)
                    specifically?
                </div>
                <div class="col-10">
                    <input class="mat-input-section" id="yearsOfExperience" placeholder="yearsOfExperience"
                        formControlName="yearsOfExperience" type="number">
                    <small class="burnt-red" *ngIf="
                        urbanPlannerInfoForm.get('yearsOfExperience').invalid &&
                        urbanPlannerInfoForm.get('yearsOfExperience').touched">
                        * Invalid years of experience.
                    </small>
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div class="col-10">
                    <span class="burnt-red bold">|</span>
                    Under which name does you/your company operate?
                </div>
                <div class="col-10">
                    <input class="mat-input-section" id="companyName" placeholder="companyName"
                        formControlName="companyName" type="text" maxlength="250">
                </div>
            </div>
        </div>
        <mat-card class="property-card mt-4">
            <div class="row paragraph">
                <h4>Property Identification</h4>
                <label class="bold">
                    <span class="burnt-red">|</span>
                    Please search and select the address of your company/offices. Three attempts allowed.
                </label>
            </div>
            <div class="row paragraph mt-2">
                <app-map-search (selectedProperty)="handleSelectedPropertyChange($event)"></app-map-search>
            </div>
            <div class="content-padding">
                <div *ngIf="isPropertySelected">
                    <div class="row paragraph mt-4">
                        <label class="bold mb-2">
                            <span class="burnt-red">|</span>
                            Is the boundary indicated on the map correct?
                        </label>
                        <mat-radio-group formControlName="isBoundaryCorrect">
                            <mat-radio-button class="mt-1" [value]="true">Yes</mat-radio-button>
                            <mat-radio-button class="mt-1" [value]="false">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div *ngIf="isPropertySelected && !isBoundaryCorrect">
                    <div class="row paragraph mt-4">
                        <h4>Property Boundary</h4>
                    </div>
                    <div class="row paragraph mt-2">
                        <div class="col-10">
                            <label for="propertyBoundaryConcern">
                                <span class="burnt-red bold">|</span>
                                Please explain your concern regarding the boundary indicated:
                            </label>
                        </div>
                        <div class="col-10 mt-1">
                            <textarea required id="propertyBoundaryConcern" class="default-textarea"
                                formControlName="propertyBoundaryConcern" type="text" maxlength="250">
                        </textarea>
                        </div>
                    </div>
                    <div class="row paragraph mt-4">
                        <h4>Property Details</h4>
                        <span class="bold">Please provide the correct details:</span>
                    </div>
                    <div class="row paragraph mt-3">
                        <div class="col-3">
                            <label for="propertyAddress">Property address:</label>
                        </div>
                        <div class="col-7">
                            <input required id="propertyAddress" class="default-input" formControlName="propertyAddress"
                                placeholder="854 Kruin Street, Sunnyside, Pretoria" type="text" maxlength="250">
                        </div>
                    </div>
                    <div class="row paragraph mt-3">
                        <div class="col-3">
                            <label for="propertyDescription">Property description:</label>
                        </div>
                        <div class="col-7">
                            <textarea required id="propertyDescription" class="default-textarea"
                                formControlName="propertyDescription"
                                placeholder="Erf 386 Meyerton | Holding 23 of Raslouw Agricultural Holdings | Portion 77 of the Farm Stoneybrooke 365-JR"
                                type="text" maxlength="250">
                        </textarea>
                        </div>
                    </div>
                    <div class="row paragraph">
                        <div class="col-3"></div>
                        <div class="col-7">
                            <label>
                                * indicated on your municipal rates account (except for eThekwini) or your title deed.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
        <div class="content-padding">
            <div class="row paragraph mt-4">
                <div class="col-10">
                    <span class="light-grey bold">|</span>
                    If applicable - your company registration number?
                </div>
                <div class="col-10">
                    <input class="mat-input-section" id="companyRegistrationNumber"
                        placeholder="companyRegistrationNumber" formControlName="companyRegistrationNumber" type="text"
                        maxlength="250">
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div class="col-10">
                    <span class="light-grey bold">|</span>
                    If applicable - your company VAT number?
                </div>
                <div class="col-10">
                    <input class="mat-input-section" id="companyVatNumber" placeholder="companyVatNumber"
                        formControlName="companyVatNumber" type="text" maxlength="250">
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div formArrayName="municipalCouncils">
                    <div class="bold mb-2">
                        <span class="burnt-red">|</span>
                        At which municipal council(s) do you mostly submit planning applications?
                    </div>
                    <div *ngFor="let council of municipalCouncils; let i = index" class="mt-1">
                        <mat-checkbox [formControlName]="i">
                            {{ council.value === 'Other' && isOtherMunicipalCouncilChecked()
                            ? council.value + ' - Please specify'
                            : council.value }}
                        </mat-checkbox>
                    </div>
                </div>
                <div *ngIf="isOtherMunicipalCouncilChecked()" class="mt-2">
                    <input required class="mat-input-section" id="otherMunicipalCouncil" maxlength="250"
                        formControlName="otherMunicipalCouncil" placeholder="Required if other is selected" type="text">
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div formArrayName="landDevelopmentTypes">
                    <div class="bold mb-2">
                        <span class="burnt-red">|</span>
                        Indicate any type of land development applications in which you have extensive experience and
                        knowledge:
                    </div>
                    <div *ngFor="let type of landDevelopmentTypes; let i = index" class="mt-1">
                        <mat-checkbox [formControlName]="i">
                            {{ type.value === 'Other' && isOtherLandDevelopmentTypeChecked()
                            ? type.value + ' - Please specify'
                            : type.value }}
                        </mat-checkbox>
                    </div>
                </div>
                <div *ngIf="isOtherLandDevelopmentTypeChecked()" class="mt-2">
                    <input required class="mat-input-section" id="otherLandDevelopmentType" maxlength="250"
                        formControlName="otherLandDevelopmentType" placeholder="Required if other is selected"
                        type="text">
                </div>
            </div>
            <div class="row paragraph mt-4">
                <label class="bold mb-2">
                    <span class="burnt-red">|</span>
                    Do you have proven experience in conducting feasibility/viability studies, calculating the maximum
                    projected ROI for developments projects?
                </label>
                <mat-radio-group formControlName="hasFeasibilityStudyExperience">
                    <mat-radio-button class="mt-1" [value]="true">Yes</mat-radio-button>
                    <mat-radio-button class="mt-1" [value]="false">No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="hasFeasibilityStudyExperience">
                <div class="row paragraph mt-4">
                    <div formArrayName="developmentSpecialties">
                        <div class="bold mb-2">
                            <span class="burnt-red">|</span>
                            In which types of developments do you specialise to do feasibility/viability studies?
                        </div>
                        <div *ngFor="let specialty of developmentSpecialties; let i = index" class="mt-1">
                            <mat-checkbox [formControlName]="i">
                                {{ specialty.value === 'Other' && isOtherDevelopmentSpecialtyChecked()
                                ? specialty.value + ' - Please specify'
                                : specialty.value }}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div *ngIf="isOtherDevelopmentSpecialtyChecked()" class="mt-2">
                        <input required class="mat-input-section" id="otherDevelopmentSpecialty" maxlength="250"
                            formControlName="otherDevelopmentSpecialty" placeholder="Required if other is selected"
                            type="text">
                    </div>
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="bold">
                    Details of a support staff member we may contact regarding <u>administrative</u> queries:
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="supportName">Name:</label>
                </div>
                <div class="col-7">
                    <input id="supportName" class="default-input" formControlName="supportName" type="text"
                        maxlength="250">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="supportSurname">Surname:</label>
                </div>
                <div class="col-7">
                    <input id="supportSurname" class="default-input" formControlName="supportSurname" type="text"
                        maxlength="250">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="supportPosition">Position:</label>
                </div>
                <div class="col-7">
                    <input id="supportPosition" class="default-input" formControlName="supportPosition" type="text"
                        maxlength="250">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="supportLandLine">Landline:</label>
                </div>
                <div class="col-7">
                    <input id="supportLandLine" class="default-input" formControlName="supportLandLine" type="text"
                        [ngClass]="{'invalid-input':
                            urbanPlannerInfoForm.get('supportLandLine').invalid &&
                            urbanPlannerInfoForm.get('supportLandLine').touched}">
                    <small class="burnt-red" *ngIf="
                        urbanPlannerInfoForm.get('supportLandLine').invalid &&
                        urbanPlannerInfoForm.get('supportLandLine').touched">
                        * Landline must contain exactly 10 digits.
                    </small>
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="supportMobile">Mobile:</label>
                </div>
                <div class="col-7">
                    <input id="supportMobile" class="default-input" formControlName="supportMobile" type="text"
                        [ngClass]="{'invalid-input':
                            urbanPlannerInfoForm.get('supportMobile').invalid &&
                            urbanPlannerInfoForm.get('supportMobile').touched}">
                    <small class="burnt-red" *ngIf="
                        urbanPlannerInfoForm.get('supportMobile').invalid &&
                        urbanPlannerInfoForm.get('supportMobile').touched">
                        * Mobile number must contain exactly 10 digits.
                    </small>
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="supportEmail">Email:</label>
                </div>
                <div class="col-7">
                    <input id="supportEmail" class="default-input" formControlName="supportEmail" type="text" [ngClass]="{'invalid-input':
                            urbanPlannerInfoForm.get('supportEmail').invalid &&
                            urbanPlannerInfoForm.get('supportEmail').touched}">
                    <small class="burnt-red" *ngIf="
                        urbanPlannerInfoForm.get('supportEmail').invalid &&
                        urbanPlannerInfoForm.get('supportEmail').touched">
                        * Invalid email address.
                    </small>
                </div>
            </div>
        </div>
        <div class="row paragraph mt-5">
            <div class="text-center">
                <button class="button" type="submit" [disabled]="urbanPlannerInfoForm.invalid">SUBMIT</button>
            </div>
        </div>
    </form>
</main>

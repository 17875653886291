<main>
	<div class="container">
		<mat-card class="row mb-3" *ngFor="let section of sections; let i = index">
			<div class="col-12 heading-2">
				<div class="row">
					<div class="col-11">{{ section.id }}. {{ section.value }}</div>
					<div class="col-1 collapse-expand">
						<button (click)="collapseOrExpandSection(section)" mat-icon-button>
							<mat-icon *ngIf="section.checked">expand_less</mat-icon>
							<mat-icon *ngIf="!section.checked">expand_more</mat-icon>
						</button>
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">Version</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsInformationData.version" placeholder="Version" />
					</div>
				</div>
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">Title Deed Number</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsInformationData.titleDeedNumber" placeholder="Title Deed" />
					</div>
				</div>
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">Capture Date</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsInformationData.captureDate" placeholder="Capture Date" />
					</div>
				</div>
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">Registration Date</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsInformationData.registrationDate"
							placeholder="Registration Date" />
					</div>
				</div>
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">Transaction Date</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsInformationData.transactionDate"
							placeholder="Transaction Date" />
					</div>
				</div>
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">Price</label>
						<input class="col-12 mt-1 riskscape-data" type="number" readonly
							[(ngModel)]="riskscapeDeedsInformationData.price" placeholder="0" />
					</div>
				</div>
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">Deeds Office</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsInformationData.deedsOffice" placeholder="Deeds Office" />
					</div>
				</div>
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">Deeds Town</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsInformationData.deedsTown" placeholder="Deeds Town" />
					</div>
				</div>
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">Deeds Authority</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsInformationData.deedsAuthority" placeholder="Deeds Authority" />
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
				<div class="paragraph mt-3 gx-2 d-flex flex-row flex-evenly">
					<div>
						<label class="col-12 bold">Name</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsCurrentOwnerInfoData.name" placeholder="Name" />
					</div>
					<div>
						<label class="col-12 bold">ID Number</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsCurrentOwnerInfoData.idNumber" placeholder="ID Number" />
					</div>
					<div>
						<label class="col-12 bold">Marital Status</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsCurrentOwnerInfoData.maritalStatus"
							placeholder="Marital Status" />
					</div>
					<div>
						<label class="col-12 bold">Owner Type</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsCurrentOwnerInfoData.ownerType" placeholder="Owner Type" />
					</div>
					<div>
						<label class="col-12 bold">Owner Share</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly placeholder="Owner Share"
							[(ngModel)]="riskscapeDeedsCurrentOwnerInfoData.ownerShare" />
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
				<div class="paragraph mt-3 gx-2 d-flex flex-row flex-double-first">
					<div>
						<label class="col-12 bold">Transaction Date</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsTransferHistoryData.transactionDate"
							placeholder="Transaction Date" />
					</div>
					<div>
						<label class="col-12 bold">Registration Date</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsTransferHistoryData.registrationDate"
							placeholder="Registration Date" />
					</div>
					<div>
						<label class="col-12 bold">Price</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsTransferHistoryData.price" placeholder="0" />
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 4" [hidden]="!section.checked">
				<div class="paragraph mt-3 gx-2 d-flex flex-row flex-evenly">
					<div>
						<label class="col-12 bold">Registration Date</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsOwnerHistoryData.registrationDate"
							placeholder="Registration Date" />
					</div>
					<div>
						<label class="col-12 bold">Title Deed</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsOwnerHistoryData.titleDeed" placeholder="Title Deed" />
					</div>
					<div>
						<label class="col-12 bold">Marital Status</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsOwnerHistoryData.name" placeholder="Marital Status" />
					</div>
					<div>
						<label class="col-12 bold">ID Number</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsOwnerHistoryData.idNumber" placeholder="ID Number" />
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 5" [hidden]="!section.checked">
				<div class="paragraph mt-3 gx-2 d-flex flex-row flex-double-first">
					<div>
						<label class="col-12 bold">Endorsement Number</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsEndorsementData.number" placeholder="0" />
					</div>
					<div>
						<label class="col-12 bold">Bond Holder</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsBondData.holder" placeholder="Bond Holder" />
					</div>
					<div>
						<label class="col-12 bold">Bond Amount</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsBondData.amount" placeholder="0" />
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 6" [hidden]="!section.checked">
				<div class="row paragraph mt-3">
					<div class="col-12">
						This property was consolidated from the following:
					</div>
				</div>
				<div class="paragraph mt-2 gx-2 d-flex flex-row flex-evenly">
					<div>
						<label class="col-12 bold">Registration Date</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.registrationDate"
							placeholder="Registration Date" />
					</div>
					<div>
						<label class="col-12 bold">Title Deed</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.titleDeed" placeholder="Title Deed" />
					</div>
					<div>
						<label class="col-12 bold">Erf/Portion</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.erfNumber" placeholder="Erf Portion No" />
					</div>
					<div>
						<label class="col-12 bold">Extent</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.extent" placeholder="Extent" />
					</div>
					<div>
						<label class="col-12 bold">Deeds Town</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.town" placeholder="Deeds Town" />
					</div>
				</div>
				<div class="row paragraph mt-3">
					<div class="col-12">
						This property was consolidated to the following:
					</div>
				</div>
				<div class="paragraph mt-2 gx-2 d-flex flex-row flex-evenly">
					<div>
						<label class="col-12 bold">Registration Date</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.registrationDate"
							placeholder="Registration Date" />
					</div>
					<div>
						<label class="col-12 bold">Title Deed</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.titleDeed" placeholder="Title Deed" />
					</div>
					<div>
						<label class="col-12 bold">Erf/Portion</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.erfNumber" placeholder="Erf Portion No" />
					</div>
					<div>
						<label class="col-12 bold">Extent</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.extent" placeholder="Extent" />
					</div>
					<div>
						<label class="col-12 bold">Deeds Town</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsConsolidationData.town" placeholder="Deeds Town" />
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 7" [hidden]="!section.checked">
				<div class="row paragraph mt-3">
					<div class="col-12">
						This property was subdivided from the following:
					</div>
				</div>
				<div class="paragraph mt-3 gx-2 d-flex flex-row flex-evenly">
					<div>
						<label class="col-12 bold">Registration Date</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsSubdivisionData.registrationDate"
							placeholder="Registration Date" />
					</div>
					<div>
						<label class="col-12 bold">Title Deed</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsSubdivisionData.titleDeed" placeholder="Title Deed" />
					</div>
					<div>
						<label class="col-12 bold">Erf/Portion</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsSubdivisionData.erfNumber" placeholder="Erf Portion No" />
					</div>
					<div>
						<label class="col-12 bold">Extent</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsSubdivisionData.extent" placeholder="Extent" />
					</div>
					<div>
						<label class="col-12 bold">Deeds Town</label>
						<input class="col-12 mt-1 riskscape-data" type="text" readonly
							[(ngModel)]="riskscapeDeedsSubdivisionData.town" placeholder="Deeds Town" />
					</div>
				</div>
			</div>
		</mat-card>
	</div>
	<div class="button-group mb-3">
		<button (click)="goToAddendumTwo()" class="footer-button background-cloud">Previous</button>
		<button (click)="goToExportReport()" class="footer-button background-green">Completed</button>
	</div>
	<mat-card class="floating-card">
		<button class="icon-button" matTooltip="Key" (click)="openReportKeyDialog()">
			<mat-icon class="material-icons-outlined">info</mat-icon>
		</button>
	</mat-card>
</main>

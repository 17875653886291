import { MetroDto, ProfessionDto, UserDto, RoleDto, UserRoleDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { LookupService, UserManagementService } from 'src/app/services/property-matrixV2/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';

@Component({
  selector: 'app-add-edit-user-dialog',
  templateUrl: './add-edit-user-dialog.component.html',
  styleUrls: ['./add-edit-user-dialog.component.scss', '../../../../../css/2-modules/_admin-portal.scss']
})
export class AddEditUserDialogComponent implements OnInit {

  roleList: RoleDto[] = [];
  professionList: ProfessionDto[] = [];
  metroList: MetroDto[] = [];
  loading: boolean = false;
  userDtoItem: UserDto = {};
  userDataForm: FormGroup;
  selectedRole: RoleDto = null;
  selectedProfession: ProfessionDto = null;
  selectedMetro: MetroDto = null;
  user: UserDto = null;
  fullUserDetail: UserDto = null;

  constructor(public dialogRef: MatDialogRef<AddEditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userManagementService: UserManagementService,
    private lookupService: LookupService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
  ) {
    this.user = data;
  }


  async getRoles() {
    try {
      await this.lookupService.apiV1LookupGetUserRolesGet().subscribe({
        next: (rolesList: RoleDto[]) => {
          this.roleList = rolesList;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load roles for users.');
        }
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  async getProfessions() {
    try {
      await this.lookupService.apiV1LookupGetProfessionsGet().subscribe({
        next: (professionList: ProfessionDto[]) => {
          this.professionList = professionList;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load roles for users.');
        }
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  async getMetro() {
    try {
      await this.lookupService.apiV1LookupGetAllMetroItemsGet().subscribe({
        next: (metroList: MetroDto[]) => {
          this.metroList = metroList;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load metro items for users.');
        }
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  ngOnInit(): void {
    this.getRoles();
    this.getProfessions();
    this.getMetro();
    this.prepareForms();
  }

  prepareForms(): void {
    if (this.user != null) {
      this.userDataForm = this.fb.group({
        firstName: [this.user.name, [Validators.required]],
        surname: [this.user.surname, [Validators.required]],
        email: [this.user.email, [Validators.required]],
        userRoles: [this.user.userRoles],
        profession: [this.user.profession],
        password: [this.user.password, [Validators.required]],
        metro: [this.user.metro],
        accountEndorsed: [this.user.endorsed, [Validators.required]],
        associatedNetworkPlanner: [""],
      });
      this.selectedMetro = this.user.metro;
      this.selectedProfession = this.user.profession;
      if (this.user.userRoles) {
        let roleDto: RoleDto = {};
        for (let index = 0; index < this.user.userRoles.length; index++) {
          const element = this.user.userRoles[index];
          roleDto = {
            name: element.roleName,
            id: element.id,
            isDeleted: element.isDeleted,
            createdBy: element.createdBy,
            createdDate: Date.parse(element.createdDate).toString(),
          }
        };
        this.selectedRole = roleDto;
      }
    }
    else {
      this.userDataForm = this.fb.group({
        firstName: ["", [Validators.required]],
        surname: ["", [Validators.required]],
        email: ["", [Validators.required]],
        userRoles: [null],
        profession: [null],
        password: ["", [Validators.required]],
        metro: [null],
        associatedNetworkPlanner: [""],
        accountEndorsed: [false, [Validators.required]],
      });
      this.selectedMetro = null;
      this.selectedProfession = null;
      this.selectedRole = null;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmSave(): void {
    try {
      if (this.userDataForm.valid) {
        this.loading = true;
        var RoleList: RoleDto[] = [];
        var UserRoleList: UserRoleDto[] = [];

        UserRoleList.push({ roleid: this.selectedRole.id, userid: null, roleName: this.selectedRole.name });
        RoleList.push(this.selectedRole);

        this.userDtoItem = {
          confirmEmail: "",
          confirmMobile: "",
          name: this.userDataForm.get("firstName").value,
          surname: this.userDataForm.get('surname').value,
          email: this.userDataForm.get('email').value,
          mobile: "",
          password: this.userDataForm.get('password').value,
          profession: this.selectedProfession,
          metro: this.selectedMetro,
          endorsed: this.userDataForm.get('accountEndorsed').value,
          userRoles: UserRoleList,
        };

        this.userManagementService.apiV1UserManagementAddUserPost({
          body: this.userDtoItem
        }).subscribe({
          next: () => {
            this.loading = false;
            this.notificationService.showSuccessMessage('Success', 'User registration was successful.');
            this.dialogRef.close();
          },
          error: (_error: any) => {
            this.loading = false;
            this.notificationService.showErrorMessage('Error', 'An error occurred while registering user.');
          }
        });
      } else {
        this.validateAllFormFields(this.userDataForm);
      }
    }
    catch (e) {
      console.error(e);
      this.notificationService.showErrorMessage("Error", "Error saving a user, please contact your administrator.");
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  compareProfession(profession1, profession2) {
    return profession1 && profession2 ? profession1.value === profession2.value : profession1 === profession2;
  }

  compareUserRole(role1, role2) {
    return role1 && role2 ? role1.value === role2.value : role1 === role2;
  }

  compareMetro(metro1, metro2) {
    return metro1 && metro2 ? metro1.value === metro2.value : metro1 === metro2;
  }
}

import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting-service/reporting.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ZoningMapDto } from 'src/app/services/property-matrixV2/models';

@Component({
  selector: 'app-zoning-map',
  templateUrl: './zoning-map.component.html',
  styleUrls: ['./zoning-map.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ZoningMapComponent implements OnInit {

  @Input() planningReportId: string;
  @Output() zoningMapChange = new EventEmitter<any>();

  zoningMapData: ZoningMapDto = {
    description: "",
    name: "",
  };
  zoningMapFileId: string;

  file: any;
  fileUrl: string = null;
  hideButtons = false;

  constructor(
    private reportingService: ReportingService,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadZoningMap();
  }

  async loadZoningMap(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetZoningMapGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response: any) => {
        this.zoningMapData = response;
        if (this.zoningMapData?.blobStorageId != null) {
          this.zoningMapFileId = this.zoningMapData?.blobStorageId;
        }
        this.loadFile();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load zoning map data.');
      }
    });
  }

  async setFile(event: { target: { files: any[]; }; }) {
    try {
      this.zoningMapFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.zoningMapData.blobStorageId = this.zoningMapFileId;
      this.emitChanges();
      this.file = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.fileUrl = event.target.result;
        this.hideButtons = true;
      }

      reader.readAsDataURL(this.file);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  replace() {
    this.fileUrl = null;
    this.hideButtons = false;
  }

  async loadFile() {
    if (this.zoningMapFileId) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(this.zoningMapFileId);
        this.fileUrl = fileUrl;
        this.hideButtons = !!fileUrl;
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  emitChanges(): void {
    this.zoningMapChange.emit(this.zoningMapData);
  }
}

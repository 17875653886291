<mat-card class="mt-2">
    <div class="row paragraph">
        <div class="col-12">
            <label class="col-12 bold">How many parcels do you recommend?</label>
        </div>
    </div>
    <div class="row paragraph mt-2">
        <div class="col-12">
            <mat-radio-group (change)="onLandParcelSelectionChange($event.value)"
                [(ngModel)]="amountOfLandParcelsOption">
                <div class="radio-container">
                    <span style="font-size: small;">
                        <mat-radio-button class="radio" [value]="1">
                            One
                        </mat-radio-button>
                    </span>
                </div>
                <div class="radio-container">
                    <span style="font-size: small;">
                        <mat-radio-button class="radio" [value]="2">
                            Two
                        </mat-radio-button>
                    </span>
                </div>
                <div class="radio-container">
                    <span style="font-size: small;">
                        <mat-radio-button class="radio" [value]="3">
                            Three
                        </mat-radio-button>
                    </span>
                </div>
                <div class="radio-container">
                    <span style="font-size: small;">
                        <mat-radio-button class="radio" [value]="4">
                            More Than Three
                        </mat-radio-button>
                    </span>
                </div>
            </mat-radio-group>
        </div>
    </div>
</mat-card>
<mat-card *ngIf="amountOfLandParcelsOption > 3" class="mt-2">
    <div class="row paragraph">
        <div class="col-12">
            <label class="col-12 bold">
                According to our calculations, based on the interpretation of the relevant planning policies, the
                property appears to have the following development potential:
            </label>
            <textarea class="col-12 mt-1 text-area" [(ngModel)]="proposedDevelopmentEnhancementText"
                placeholder="Proposed Development Enhancement"
                (ngModelChange)="onProposedDevelopmentEnhancementChange($event)">
            </textarea>
        </div>
    </div>
</mat-card>
<mat-card *ngIf="amountOfLandParcelsOption > 0 && amountOfLandParcelsOption <= 3" class="mt-2">
    <div class="row paragraph">
        <div class="col-12">
            <label class="col-12 bold">
                According to our calculations, based on the interpretation of the relevant planning policies, the
                following potential development is proposed:
            </label>
            <input class="col-12 mt-1" type="text" placeholder="Copy chosen improvement option description."
                [(ngModel)]="proposedDevelopmentEnhancementText"
                (ngModelChange)="onProposedDevelopmentEnhancementChange($event)">
        </div>
    </div>
</mat-card>
<mat-card *ngFor="let scenario of proposedDevelopmentScenarios; let i = index" class="mt-2">
    <app-land-parcel [scenario]="scenario" [index]="i" [municipality]="municipality"
        (scenarioChange)="onScenarioChange(i, $event)">
    </app-land-parcel>
</mat-card>
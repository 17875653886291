import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { initialWayForwardText } from 'src/app/constants/data/way-forward-initial-data';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WayForwardDto } from 'src/app/services/property-matrixV2/models';

@Component({
  selector: 'app-way-forward',
  templateUrl: './way-forward.component.html',
  styleUrls: ['./way-forward.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class WayForwardComponent implements OnInit {

  @Input() planningReportId: string;
  @Output() wayForwardChange = new EventEmitter<WayForwardDto>();

  wayForwardData: WayForwardDto = {
    description: "",
  };

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadWayForward();
  }

  async loadWayForward(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetWayForwardGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response: WayForwardDto) => {
        this.wayForwardData = response;
        if (this.wayForwardData?.description === '' || this.wayForwardData?.description === null) {
          this.wayForwardData.description = initialWayForwardText;
        }
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load way forward data.');
      }
    });
  }

  emitChanges() {
    this.wayForwardChange.emit(this.wayForwardData);
  }
}

<form id="priceForm" novalidate #priceForm="ngForm" class="submission-form">
    <section>
        <div class="heading-2 mt-1">STEP 3: PURCHASE PRICE</div>
        <div class="heading-5 mt-2">
            <span class="burnt-red">| </span>What would you like to offer for this property?
        </div>
        <div class="row">
            <div class="col-12 inline paragraph">
                <label for="askingPrice">R </label>
                <input value="" data-type="currency" class="input-inner-padding" name="askingPrice"
                    #askingPrice="ngModel" [(ngModel)]="form.propertyOffer" required
                    (change)="calculateMortgageBondForPurchasePrice()" onClick="this.select();" id="askingPrice" />
                (purchase price to be inclusive of VAT where applicable)
            </div>
        </div>
        <div class="paragraph">
            <b>Please note</b>: Ridiculously low offers will unfortunately have to be discarded. All property advertised
            on this website went through a value assessment
            process and asking prices are thus market related.
        </div>
        <div class="heading-5 mt-2">
            <span class="burnt-red">| </span>What percentage of the purchase price are you willing to pay as a deposit?
        </div>
        <div class="inline paragraph">
            <label for="depositPercentage">Percentage: </label>
            <input value="" data-type="currency" class="input-inner-padding" name="depositPercentage"
                #depositPercentage="ngModel" [(ngModel)]="form.depositPercentage" required
                (change)="calculateMortgageBondForPurchasePrice()" onClick="this.select();" max="100"
                id="depositPercentage" />
            %
        </div>
        <div class="heading-5 mt-2">
            <span class="burnt-red">| </span>Within how many days (after the offer to purchase is signed by both
            parties), will you be able to pay the deposit?
        </div>
        <div class="row">
            <div class="col-5 inline">
                <label for="txtDaysToPayDeposit" class="paragraph">Days: </label>
                <input id="txtDaysToPayDeposit" name="daysToPayDeposit" onClick="this.select();" required
                    class="input-inner-padding paragraph" required [(ngModel)]="form.daysToPayDeposit">
            </div>
        </div>
        <div *ngIf="form.propertyOffer && form.depositPercentage && form.daysToPayDeposit">
            <div class="heading-5">
                <span class="burnt-red">| </span>How would you like to buy this property?
            </div>
            <div class="row">
                <mat-radio-group name="propertyPurchaseMethod" [(ngModel)]="form.propertyPurchaseMethod">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of purchaseMethodRadioOptions"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div *ngIf="form.propertyPurchaseMethod == 3 || form.propertyPurchaseMethod == 4" class="submission-form">
            <div class="heading-5">
                <span class="burnt-red">| </span>Do you intend to obtain a mortgage bond for the balance of the purchase
                price:&nbsp;<span class="coral-text">R {{calculatedMortgageBondForPurchasePrice}}</span>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-radio-group name="useMortgageBondOfPurchaserPrice"
                        [(ngModel)]="useMortgageBondOfPurchaserPrice">
                        <mat-radio-button class="paragraph"
                            *ngFor="let selectionValue of intendToObtainMortgageBondRadioOptions" (click)="focusOther()"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                            <span *ngIf="selectionValue.key == 2">&nbsp;
                                <input name="mortgageBondForPurchasePrice" class="paragraph input-inner-padding"
                                    [(ngModel)]="form.mortgageBondForPurchasePrice" value="" type="number"
                                    onClick="this.select();" [readonly]="useMortgageBondOfPurchaserPrice != 2"
                                    (keypress)="preventDecimals($event)" id="mortgageBondForPurchasePrice"
                                    [required]="useMortgageBondOfPurchaserPrice == 2"
                                    #mortgageBondForPurchasePrice="ngModel" placeholder="mortgageBondForPurchasePrice">
                            </span>
                            <span [ngClass]="{'explain-text': form.mortgageBondOther.length == 0}"
                                *ngIf="selectionValue.key == 3"> – Please explain</span>
                        </mat-radio-button>
                        <div *ngIf="useMortgageBondOfPurchaserPrice == 3">
                            <div class="row">
                                <div class="col-8">
                                    <input name="mortgageBondOther" type="text"
                                        class="paragraph mat-input-section mat-input-section-other"
                                        [(ngModel)]="form.mortgageBondOther" placeholder="mortgageBondOther"
                                        [required]="useMortgageBondOfPurchaserPrice == 3" #mortgageBondOther="ngModel">
                                </div>
                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div *ngIf="form.propertyPurchaseMethod == 3 && useMortgageBondOfPurchaserPrice > 0"
                class="submission-form">
                <div class="heading-5 mt-2">
                    <span class="burnt-red">| </span>Within how many days (after the offer to purchase is signed by both
                    parties), will you be able to obtain the mortgage bond?
                </div>
                <div class="row">
                    <div class="col-5 inline">
                        <label for="txtDaysToObtainMortgageDaysToPayDeposit" class="paragraph">Days: </label>
                        <input id="txtDaysToObtainMortgageDaysToPayDeposit" name="daysToObtainMortgage"
                            onClick="this.select();" class="input-inner-padding paragraph" required
                            (keypress)="preventDecimals($event)" [(ngModel)]="form.daysToObtainMortgage">
                    </div>
                </div>
            </div>
            <div *ngIf="form.propertyPurchaseMethod == 4 && useMortgageBondOfPurchaserPrice > 0"
                class="submission-form">
                <div class="heading-5">
                    <span class="burnt-red">| </span>Do you give permission to Property Matrix to forward your details
                    to a bond originator, who will contact you shortly?
                </div>
                <div class="row">
                    <mat-radio-group name="hasPermissionToShareDetails" [(ngModel)]="hasPermissionToShareDetails">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div *ngIf="form.propertyPurchaseMethod > 0">
            <span class="paragraph"><b><u>Please confirm</u></b></span>
            <div class="col-12">
                <label class="checkbox-container paragraph mt-1">
                    I declare that all the information provided above is true and accurate and that I will provide
                    <b>Property Matrix</b> with the supporting documentation
                    once my offer is accepted. Click <a (click)="openSideNav($event)" class="here">here</a> for details
                    on what you will need to submit.
                    <input type="checkbox" name="acceptDeclaration" [(ngModel)]="acceptDeclaration" />
                    <span class="checkmark"></span>
                </label>
            </div>
            <div *ngIf="acceptDeclaration">
                <div class="row text-center pt-5">
                    <div class="col-12">
                        <button id="btnSaveFinal" class="button heading-4" type="button"
                            [disabled]="priceForm.invalid || (form.propertyPurchaseMethod == 4 && useMortgageBondOfPurchaserPrice > 0 && hasPermissionToShareDetails == 0)
                        || ((form.propertyPurchaseMethod == 3 || form.propertyPurchaseMethod == 4) && useMortgageBondOfPurchaserPrice == 0)" (click)="submitForm()">SUBMIT</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>
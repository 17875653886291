import { UserManagementService } from 'src/app/services/property-matrixV2/services/user-management.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { UserDto } from 'src/app/services/property-matrixV2/models/user-dto';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { data } from 'jquery';

import { ActionOption, ColumnConfig, DataTableComponent } from '../../../shared/components/data-table/data-table.component';
import { ConfigUserPermissionService } from '../../../services/property-matrixV2/custom-services/userPermission.service';
import { AddEditUserDialogComponent } from '../admin-users/add-edit-user-dialog/add-edit-user-dialog.component';
import { UserActionDialogComponent } from '../admin-users/user-action-dialog/user-action-dialog.component';
import { TagsKeyDialogComponent } from './tags-key-dialog/tags-key-dialog.component';
import { User } from '../../../models/User';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  loading: boolean = false;
  users: UserDto[] = [];
  networkUserSelected: boolean = false;
  roles = ['Public', 'Central', 'Extended Professionals', 'Assistants'];

  data = [
    { fullName: '', email: '', tags: '', verified: false, actions: '', id: '' },
  ];

  columns: ColumnConfig[] = [
    { columnDef: 'fullName', header: 'Full Name', headerAlign: 'left', cellAlign: 'left', width: 20 },
    { columnDef: 'email', header: 'Email', headerAlign: 'left', cellAlign: 'left', width: 30 },
    {
      columnDef: 'tags', header: 'Tags', headerAlign: 'left', cellAlign: 'left', width: 20,
      headerButton: {
        icon: 'info',
        action: () => this.openTagsDialog(),
        color: 'coral'
      }
    },
    { columnDef: 'verified', header: 'Account Verified', isBoolean: true, headerAlign: 'center', cellAlign: 'center', width: 15 },
    { columnDef: 'actions', header: 'Actions', isAction: true, headerAlign: 'center', cellAlign: 'center', width: 15 }
  ];

  actionOptions: ActionOption[] = [
    { label: 'Unverify', action: (user: UserDto) => this.openActionDialog('unverify', user) },
    { label: 'Remove', action: (user: UserDto) => this.openActionDialog('remove', user) },
    { label: 'Edit User', action: (user: UserDto) => this.openEditDialog('edit', user) },
    { label: 'View Profile', action: (user: UserDto) => this.openActionDialog('viewProfile', user) }
  ];

  dataSource: MatTableDataSource<any>;

  requiredRolesFull: string[] = [];
  requiredRolesView: string[] = [];
  currentUserRoles: string[];

  constructor(
    public dialog: MatDialog,
    private userManagementService: UserManagementService,
    private notificationService: NotificationService,
    private _userPermissionService: ConfigUserPermissionService,
  ) { }

  ngOnInit(): void {
    this.getUserDetail();
    this.getUserPermissionList();
    this.getUsers();
  }

  toggleRoleFilter(event: any) {
    const button = event.currentTarget;
    if (button.classList.contains('active')) {
      button.classList.remove('active');
    } else {
      const buttons = document.querySelectorAll('.role-filter-button');
      buttons.forEach(btn => btn.classList.remove('active'));
      button.classList.add('active');
    }
  }

  toggleNetworkFilter() {
    this.networkUserSelected = !this.networkUserSelected;
  }

  getUsers() {
    this.loading = true;
    this.userManagementService.apiV1UserManagementGetUsersGet().subscribe({
      next: (users: UserDto[]) => {
        this.users = users;
        this.populateTableData(users);
        this.dataTable.dataSource = new MatTableDataSource(this.data);
        this.dataTable.dataSource.sort = this.dataTable.sort;
        this.dataTable.dataSource.paginator = this.dataTable.paginator;
        this.dataTable.dataSource.filterPredicate = (data: any, filter: string) => {
          const searchStr = filter.toLowerCase();
          return data.fullName.toLowerCase().includes(searchStr) ||
            data.email.toLowerCase().includes(searchStr) ||
            data.tags.toLowerCase().includes(searchStr);
        };
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not fetch users.');
      }
    });
    this.loading = false;
  }

  populateTableData(users: UserDto[]) {
    this.data = users.map(user => ({
      fullName: `${user.name} ${user.surname}`,
      email: user.email,
      tags: user.userRoles?.map(userRole => userRole.roleName).join(', ') || '',
      verified: !!user.confirmEmail,
      actions: '',
      id: user.id
    }));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataTable.applyFilter(filterValue);
  }

  openAddEditDialog(): void {
    const dialogAdd = this.dialog.open(AddEditUserDialogComponent, {
      width: '30vw',
      height: '90vh'
    });
  }

  openEditDialog(actionType: string, user: UserDto): void {
    if (this.getActionsForUser().includes('edit')) {
      if (user != null) {
        this.userManagementService.apiV1UserManagementGetCurrentUserByIdGet({ id: user.id }).subscribe(result => {
          if (result) {
            const editUserDialog = this.dialog.open(AddEditUserDialogComponent, {
              data: result,
              width: '30vw',
              height: '90vh'
            });
            editUserDialog.afterClosed().subscribe(result => {
              if (result) {
                this.getUsers();
              }
            });
          } else {
            this.notificationService.showErrorMessage('Error', 'Error fetching current user.');
          }
        });
      } else {
        this.notificationService.showErrorMessage('Error', 'No current user data selected.');
      }
    } else {
      this.notificationService.showWarningMessage("Insufficient role", "Current logged in user does not have edit permissions or role linked.");
    }
  }

  openTagsDialog(): void {
    this.dialog.open(TagsKeyDialogComponent, {
      width: '35vw',
      height: '75vh'
    });
  }

  openActionDialog(actionType: string, user: UserDto): void {
    if (this.getActionsForUser().includes('edit')) {
      const dialogRef = this.dialog.open(UserActionDialogComponent, {
        width: '30vw',
        height: '30vh',
        data: { actionType, user }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.action === 'unverify' || result.action === 'remove') {
            this.getUsers();
          }
        }
      });
    } else {
      this.notificationService.showWarningMessage("Insufficient role", "Current logged in user does not have edit permissions or role linked.");
    }
  }

  private getUserDetail() {
    let user = sessionStorage.getItem('user');
    if (user != null) {
      let currentUser = JSON.parse(JSON.parse(user)) as User;

      for (let index = 0; index < currentUser.roles.length; index++) {
        var tokenRoles = currentUser.roles[index];
        this.currentUserRoles.push(tokenRoles);
      }
    } else {
      this.loadCurrentUserRole();
    }
  }

  private loadCurrentUserRole(): void {
    this.userManagementService.apiV1UserManagementGetUserTokenGet().subscribe(roleBasedAccessDto => {
      this.currentUserRoles = roleBasedAccessDto.roles;
    });
  }

  private getUserPermissionList(): void {
    this._userPermissionService.getRequiredRoles().subscribe(data => {
      this.requiredRolesFull = data.requiredRolesFull;
      this.requiredRolesView = data.requiredRolesView;
    });
  }

  private getActionsForUser() {
    if (this.hasRequiredRoleFull()) {
      return ['view', 'edit'];
    } else if (this.hasRequiredRoleView()) {
      return ['view'];
    }
    return [];
  }

  hasRequiredRoleFull(): boolean {
    return this.currentUserRoles.some(role => this.requiredRolesFull.includes(role));
  }

  hasRequiredRoleView(): boolean {
    return this.currentUserRoles.some(role => this.requiredRolesView.includes(role));
  }
}
<div class="loading" *ngIf="loading == true">Loading&#8230;</div>

<main class="main-container">
    <form [formGroup]="propertyDetailsForm" (ngSubmit)="onSubmit()">
        <div class="content-padding">

            <div class="row paragraph mt-3">
                <div class="col-12">
                    <h2 class="step-heading">Step 2: Property Details</h2>
                </div>
            </div>

            <div class="row paragraph mt-4">
                <label class="bold mb-2">
                    <span class="burnt-red">|</span>
                    The owner(s) of this property wish to sell:
                </label>
                <mat-radio-group formControlName="wishToSell" (change)="onWishToSellChange($event)">
                    <div *ngFor="let option of wishToSellOptions">
                        <mat-radio-button [value]="option">
                            {{ option.value }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>

            <div *ngIf="selectedWishToSell?.intValue === 2">
                <div class="row paragraph mt-4">
                    <div class="col-12">
                        <label class="bold">Please note:</label>
                    </div>
                    <div class="col-12">
                        <label>Separate properties will each require an individual, separate form.</label>
                    </div>
                </div>
            </div>

            <div *ngIf="selectedWishToSell?.intValue === 3">
                <div class="row paragraph mt-4">
                    <label class="bold mb-2">
                        <span class="burnt-red">|</span>
                        The joint properties are owned:
                    </label>
                    <mat-radio-group formControlName="jointProperties" (change)="onJointOwnershipTypeChange($event)">
                        <div *ngFor="let type of jointOwnershipTypes">
                            <mat-radio-button [value]="type" (click)="openDialog(type)">
                                {{ type.value }}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </div>

        <mat-card class="property-card mt-4">
            <div class="row paragraph">
                <h4>Property Identification</h4>
                <label class="bold">
                    <span class="burnt-red">|</span>
                    Please search and select the property (land parcel) on the map. Three attempts possible.
                </label>
            </div>
            <div class="row paragraph map-container mt-2">
                <div class="map-plugin-container col-12 col-md-8">
                    <app-map-search (selectedProperty)="handleSelectedPropertyChange($event)"></app-map-search>
                </div>
                <div class="map-sidenav-container col-12 col-md-4">
                    <div class="sidenav-header">
                        <h2>Confirm Property</h2>
                    </div>
                    <div class="sidenav-body">
                        <div *ngIf="showConfirmation" class="confirmation-container">
                            <p>Are you confident that this is your property?</p>
                            <b>NB: Three attempts allowed.</b>
                            <div class="button-container">
                                <button class="disagree-button" (click)="cancelSelection()">Cancel</button>
                                <button class="agree-button" (click)="confirmSelection()">Agree</button>
                            </div>
                        </div>
                        <div *ngIf="cadastreImageUrl && !showConfirmation">
                            <div class="sidenav-sub-heading">
                                <h2>Property Boundary</h2>
                            </div>
                            <div id="sidebar-map" class="small-map">
                                <img [src]="cadastreImageUrl" class="cadastre-image" alt="Cadastre Image">
                            </div>
                            <div class="button-container">
                                <button class="incorrect-button" [class.selected]="selectBoundary === 1"
                                    (click)="confirmPropertyBoundary(1)" type="button">
                                    Incorrect
                                </button>
                                <button class="unsure-button" [class.selected]="selectBoundary === 2"
                                    (click)="confirmPropertyBoundary(2)" type="button">
                                    Unsure
                                </button>
                                <button class="correct-button" [class.selected]="selectBoundary === 3"
                                    (click)="confirmPropertyBoundary(3)" type="button">
                                    Correct
                                </button>
                            </div>
                            <div class="sidenav-sub-heading">
                                <h2>Property Details</h2>
                            </div>
                            <div class="property-details-table">
                                <table>
                                    <tr>
                                        <td>Unit Number</td>
                                        <td>{{ this.unitNumber }}</td>
                                    </tr>
                                    <tr>
                                        <td>Erf/Portion Nr</td>
                                        <td>{{ this.erfPortion }}</td>
                                    </tr>
                                    <tr>
                                        <td>Short Address</td>
                                        <td>{{ this.shortAddress }}</td>
                                    </tr>
                                    <tr>
                                        <td>Suburb Name</td>
                                        <td>{{ this.suburbName }}</td>
                                    </tr>
                                    <tr>
                                        <td>City/Town</td>
                                        <td>{{ this.cityTown }}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="button-container">
                                <button class="incorrect-button" [class.selected]="selectDetails === 1"
                                    (click)="confirmPropertyDetails(1)" type="button">
                                    Incorrect
                                </button>
                                <button class="unsure-button" [class.selected]="selectDetails === 2"
                                    (click)="confirmPropertyDetails(2)" type="button">
                                    Unsure
                                </button>
                                <button class="correct-button" [class.selected]="selectDetails === 3"
                                    (click)="confirmPropertyDetails(3)" type="button">
                                    Correct
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-padding">
                <div *ngIf="propertyKey && agreeWithPropertyBoundary === false">
                    <div class="row paragraph mt-4">
                        <h4>Property Boundary</h4>
                    </div>
                    <div class="row paragraph mt-2">
                        <div class="col-10">
                            <label for="propertyBoundaryConcern">
                                <span class="burnt-red bold">|</span>
                                Please explain your concern regarding the boundary indicated:
                            </label>
                        </div>
                        <div class="col-10 mt-1">
                            <textarea required id="propertyBoundaryConcern" class="default-textarea"
                                formControlName="propertyBoundaryConcern" type="text" maxlength="250">
                        </textarea>
                        </div>
                    </div>
                </div>
                <div *ngIf="propertyKey && confirmCorrectAddressDetails === false">
                    <div class="row paragraph mt-4">
                        <h4>Property Details</h4>
                        <span class="bold">Please provide the correct details:</span>
                    </div>
                    <div class="row paragraph mt-3">
                        <div class="col-3">
                            <label for="propertyAddress">Property address:</label>
                        </div>
                        <div class="col-7">
                            <input required id="propertyAddress" class="default-input" formControlName="propertyAddress"
                                placeholder="854 Kruin Street, Sunnyside, Pretoria" type="text" maxlength="250">
                        </div>
                    </div>
                    <div class="row paragraph mt-3">
                        <div class="col-3">
                            <label for="propertyDescription">Property description:</label>
                        </div>
                        <div class="col-7">
                            <textarea required id="propertyDescription" class="default-textarea"
                                formControlName="propertyDescription"
                                placeholder="Erf 386 Meyerton | Holding 23 of Raslouw Agricultural Holdings | Portion 77 of the Farm Stoneybrooke 365-JR"
                                type="text" maxlength="250">
                        </textarea>
                        </div>
                    </div>
                    <div class="row paragraph">
                        <div class="col-3"></div>
                        <div class="col-7">
                            <label>
                                * indicated on your municipal rates account (except for eThekwini) or your title deed.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>

        <div class="content-padding">
            <div class="row paragraph mt-5">
                <h4>Asking Price</h4>
            </div>

            <div class="row paragraph mt-4">
                <div class="col-10 bold">
                    <span class="burnt-red">|</span>
                    What is the Asking Price, excluding VAT, as stipulated in the signed Exclusive Mandate?
                </div>
            </div>

            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="askingPrice">Asking price (R):</label>
                </div>
                <div class="col-7">
                    <input required id="askingPrice" class="default-input" formControlName="askingPrice" type="number"
                        min="0">
                </div>
            </div>

            <div class="row paragraph mt-4">
                <div class="col-10 bold">
                    <span class="burnt-red">|</span>
                    Please confirm:
                </div>
            </div>

            <div class="row paragraph mt-2">
                <div class="col-12">
                    <mat-checkbox id="isAskingPriceMarketRelated" formControlName="isAskingPriceMarketRelated">
                        I am confident that the asking price is market related and in line with the open market value of
                        this property.
                    </mat-checkbox>
                </div>
            </div>

            <div class="row paragraph mt-1">
                <div class="col-12">
                    <mat-checkbox id="hasInformedSellerOfExpenses" formControlName="hasInformedSellerOfExpenses">
                        I have made the Seller aware of expenses such as, electrical-, plumbing-, gas certificates,
                        tenant deposit, bond cancellation fees, etc.
                    </mat-checkbox>
                </div>
            </div>

            <div class="row paragraph mt-4">
                <div formArrayName="askingPriceConfidenceReasons">
                    <div class="bold mb-2">
                        <span class="burnt-red">|</span>
                        Kindly indicate the reason(s) for your confidence in determining a market related asking price:
                    </div>
                    <div *ngFor="let reason of askingPriceConfidenceReasons; let i = index" class="mt-1">
                        <mat-checkbox *ngIf="reason?.value" [formControlName]="i">
                            {{ reason.value === 'Other' && isOtherConfidenceReasonChecked()
                            ? reason.value + ' - Please specify'
                            : reason.value }}
                          </mat-checkbox>
                    </div>
                </div>
                <div *ngIf="isOtherConfidenceReasonChecked()" class="mt-2">
                    <input required class="mat-input-section" id="otherAskingPriceConfidenceReason" maxlength="250"
                        formControlName="otherAskingPriceConfidenceReason" placeholder="Required if other is selected"
                        type="text">
                </div>
            </div>
        </div>

        <div class="row paragraph mt-5">
            <div class="text-center">
                <button class="button" type="submit" [disabled]="propertyDetailsForm.invalid">SUBMIT</button>
            </div>
        </div>
    </form>
</main>
<main>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Metric Unit</label>
            <mat-select class="col-12 mt-1" (selectionChange)="onMetricUnitChange($event.value)"
                [(ngModel)]="areaAvailableData.metricUnit">
                <mat-option *ngFor="let item of metricUnits" [value]="item.key">
                    {{ item.value }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="paragraph mt-3 gx-2 d-flex flex-row flex-double-first">
        <div>
            <label class="col-12 bold">
                Size of property (deeds extent to be on par with S.G. diagram) ({{ areaAvailableData.metricUnit }})
            </label>
            <input class="col-12 mt-1" type="number" placeholder="1,2" [(ngModel)]="areaAvailableData.propertySize"
                (ngModelChange)="onPropertySizeChange()">
        </div>
        <div>
            <label class="col-12 bold">Addendums</label>
            <input class="col-12 mt-1" type="text" placeholder="See Addendums 1 and 2"
                [(ngModel)]="areaAvailableData.propertySizeAddendum" (ngModelChange)="emitChanges()">
        </div>
    </div>
    <div class="paragraph mt-3 gx-2 d-flex flex-row flex-double-first">
        <div>
            <label class="col-12 bold">Unusable space ({{ areaAvailableData.metricUnit }})</label>
            <input class="col-12 mt-1" type="number" placeholder="0,3" [(ngModel)]="areaAvailableData.unusableSpace"
                (ngModelChange)="onUnusableSpaceChange()">
        </div>
        <div>
            <label class="col-12 bold">Addendums</label>
            <input class="col-12 mt-1" type="text" placeholder="See Addendums 3 and 4"
                [(ngModel)]="areaAvailableData.unusableSpaceAddendum" (ngModelChange)="emitChanges()">
        </div>
    </div>
    <div class="paragraph mt-3 gx-2 d-flex flex-row flex-double-first">
        <div>
            <label class="col-12 bold">Potential usable property ({{ areaAvailableData.metricUnit }})</label>
            <input class="col-12 mt-1 matrix-data" type="number" readonly placeholder="0.3"
                [(ngModel)]="areaAvailableData.potentialUsableSpace" (ngModelChange)="emitChanges()">
        </div>
        <div>
            <label class="col-12 bold">Addendums</label>
            <input class="col-12 mt-1" type="text" placeholder="See Addendums 5 and 6"
                [(ngModel)]="areaAvailableData.potentialUsableSpaceAddendum" (ngModelChange)="emitChanges()">
        </div>
    </div>
</main>
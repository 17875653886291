<main>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <div class="col-12 heading-3">Existing Land Use Rights</div>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Scheme Name</label>
            <mat-select class="col-12 mt-1" [(value)]="existingZoningControlsData.schemeId"
                (selectionChange)="onSchemeChange($event)">
                <mat-option [value]=null>Select Option</mat-option>
                <mat-option *ngFor="let scheme of schemeData" [value]="scheme.id">
                    {{ scheme.description }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Current Zoning</label>
            <mat-select class="col-12 mt-1" [(value)]="existingZoningControlsData.zoningId"
                (selectionChange)="onZoningChange($event)">
                <mat-option [value]=null>Select Option</mat-option>
                <mat-option *ngFor="let zoning of zoningData" [value]="zoning.id">
                    {{ zoning.description }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-4">
        <div class="col-12">
            <label class="col-12 bold">Uses Permitted On Site</label>
            <textarea placeholder="Uses Permitted" class="col-12 mt-1 coral-text text-area"
                readonly>{{ possibleUsesPermittedOnSite }}</textarea>
        </div>
    </div>
    <div class="row paragraph mt-4">
        <div class="col-12">
            <label class="col-12 bold">Select the permitted uses according to the applicable land use rights.</label>
            <mat-select class="col-12 mt-1 coral-text" [(value)]="existingZoningControlsData.usesPermittedOnSiteIds"
                (selectionChange)="onUsesPermittedOnSiteChange($event)" multiple>
                <mat-option *ngFor="let landUse of usesPermittedOnSite" [value]="landUse.id">
                    {{ landUse.descriptionShort }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-4">
        <div class="col-12">
            <label class="col-12 bold">Possible Additional Use (E.g: Consent Use)</label>
            <textarea placeholder="Additional Use" class="col-12 mt-1 coral-text text-area"
                readonly>{{ possibleAdditionalUses }}</textarea>
        </div>
    </div>
    <div class="row paragraph mt-4">
        <div class="col-12">
            <label class="col-12 bold">Select the approved additional uses according to the applicable land use
                rights.</label>
            <mat-select class="col-12 mt-1" [(value)]="existingZoningControlsData.approvedAdditionalUseIds"
                (selectionChange)="onApprovedAdditionalUsesChange($event)" multiple>
                <mat-option *ngFor="let landUse of approvedAdditionalUses" [value]="landUse.id">
                    {{ landUse.descriptionShort }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-4">
        <div class="col-12">
            <div class="col-12 heading-3">Zoning Controls</div>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Current planning application</label>
            <input class="col-12 mt-1" type="text" placeholder="General plan approved"
                [(ngModel)]="existingZoningControlsData.currentPlanningApplication" (ngModelChange)="emitChanges()">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Addendum</label>
            <input class="col-12 mt-1" type="text" placeholder="See Addendum 6"
                [(ngModel)]="existingZoningControlsData.addendum" (ngModelChange)="emitChanges()">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Coverage* permitted</label>
            <input class="col-12 mt-1" type="number" placeholder="60"
                [(ngModel)]="existingZoningControlsData.coveragePermitted" (ngModelChange)="emitChanges()" min="0"
                step="0.01">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Height permitted</label>
            <input class="col-12 mt-1" type="number" placeholder="3" min="0" step="0.01"
                [(ngModel)]="existingZoningControlsData.heightPermitted" (ngModelChange)="emitChanges()">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Floor area ratio*</label>
            <input class="col-12 mt-1" type="number" placeholder="1.5" min="0" step="0.01"
                [(ngModel)]="existingZoningControlsData.floorAreaRatio" (ngModelChange)="emitChanges()">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Density</label>
            <input class="col-12 mt-1" type="number" placeholder="20" [(ngModel)]="existingZoningControlsData.density"
                (ngModelChange)="emitChanges()" min="0" step="0.01">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Minimum erf size</label>
            <input class="col-12 mt-1" type="number" placeholder="950" min="0" step="0.01"
                [(ngModel)]="existingZoningControlsData.minimumErfSize" (ngModelChange)="emitChanges()">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Current permitted bulk</label>
            <input class="col-12 mt-1" type="number" placeholder="800" min="0" step="0.01"
                [(ngModel)]="existingZoningControlsData.currentPermittedBulk" (ngModelChange)="emitChanges()">
        </div>
    </div>
</main>
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class MapStateCheckService {

  constructor() { }

  //create a behavior subject to store the state of the map
  private mapState = new BehaviorSubject<boolean>(false);
  
  //create an observable to subscribe to the map state
  mapState$ = this.mapState.asObservable();

  //method to update the map state
  updateMapState(state: boolean) {
    this.mapState.next(state);
  }
}

/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EstateAgentDto } from '../models/estate-agent-dto';
import { EstateAgentInviteDto } from '../models/estate-agent-invite-dto';
import { GenericResultDto } from '../models/generic-result-dto';
import { UrbanPlannerDto } from '../models/urban-planner-dto';
import { UserDto } from '../models/user-dto';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1RegistrationRegisterUrbanPlannerPost
   */
  static readonly ApiV1RegistrationRegisterUrbanPlannerPostPath = '/api/v1/Registration/RegisterUrbanPlanner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RegistrationRegisterUrbanPlannerPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationRegisterUrbanPlannerPost$Response(params?: {
    context?: HttpContext
    body?: UrbanPlannerDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UrbanPlannerDto>> {

    const rb = new RequestBuilder(this.rootUrl, RegistrationService.ApiV1RegistrationRegisterUrbanPlannerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UrbanPlannerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1RegistrationRegisterUrbanPlannerPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationRegisterUrbanPlannerPost(params?: {
    context?: HttpContext
    body?: UrbanPlannerDto
  }
): Observable<UrbanPlannerDto> {

    return this.apiV1RegistrationRegisterUrbanPlannerPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UrbanPlannerDto>) => r.body as UrbanPlannerDto)
    );
  }

  /**
   * Path part for operation apiV1RegistrationRegisterEstateAgentPost
   */
  static readonly ApiV1RegistrationRegisterEstateAgentPostPath = '/api/v1/Registration/RegisterEstateAgent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RegistrationRegisterEstateAgentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationRegisterEstateAgentPost$Response(params?: {
    context?: HttpContext
    body?: EstateAgentDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<EstateAgentDto>> {

    const rb = new RequestBuilder(this.rootUrl, RegistrationService.ApiV1RegistrationRegisterEstateAgentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<EstateAgentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1RegistrationRegisterEstateAgentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationRegisterEstateAgentPost(params?: {
    context?: HttpContext
    body?: EstateAgentDto
  }
): Observable<EstateAgentDto> {

    return this.apiV1RegistrationRegisterEstateAgentPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<EstateAgentDto>) => r.body as EstateAgentDto)
    );
  }

  /**
   * Path part for operation apiV1RegistrationInviteEstateAgentPost
   */
  static readonly ApiV1RegistrationInviteEstateAgentPostPath = '/api/v1/Registration/InviteEstateAgent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RegistrationInviteEstateAgentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationInviteEstateAgentPost$Response(params?: {
    context?: HttpContext
    body?: EstateAgentInviteDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<GenericResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, RegistrationService.ApiV1RegistrationInviteEstateAgentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<GenericResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1RegistrationInviteEstateAgentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationInviteEstateAgentPost(params?: {
    context?: HttpContext
    body?: EstateAgentInviteDto
  }
): Observable<GenericResultDto> {

    return this.apiV1RegistrationInviteEstateAgentPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<GenericResultDto>) => r.body as GenericResultDto)
    );
  }

  /**
   * Path part for operation apiV1RegistrationGetUserByEstateAgentPrincipalUserIdGet
   */
  static readonly ApiV1RegistrationGetUserByEstateAgentPrincipalUserIdGetPath = '/api/v1/Registration/GetUserByEstateAgentPrincipalUserId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RegistrationGetUserByEstateAgentPrincipalUserIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RegistrationGetUserByEstateAgentPrincipalUserIdGet$Response(params?: {
    principalUserId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, RegistrationService.ApiV1RegistrationGetUserByEstateAgentPrincipalUserIdGetPath, 'get');
    if (params) {
      rb.query('principalUserId', params.principalUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1RegistrationGetUserByEstateAgentPrincipalUserIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RegistrationGetUserByEstateAgentPrincipalUserIdGet(params?: {
    principalUserId?: string;
    context?: HttpContext
  }
): Observable<UserDto> {

    return this.apiV1RegistrationGetUserByEstateAgentPrincipalUserIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation apiV1RegistrationGetNetworkAgentsGet
   */
  static readonly ApiV1RegistrationGetNetworkAgentsGetPath = '/api/v1/Registration/GetNetworkAgents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RegistrationGetNetworkAgentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RegistrationGetNetworkAgentsGet$Response(params?: {
    userId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RegistrationService.ApiV1RegistrationGetNetworkAgentsGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1RegistrationGetNetworkAgentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RegistrationGetNetworkAgentsGet(params?: {
    userId?: string;
    context?: HttpContext
  }
): Observable<Array<UserDto>> {

    return this.apiV1RegistrationGetNetworkAgentsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation apiV1RegistrationDeLinkPrincipleForUserIdAsyncPost
   */
  static readonly ApiV1RegistrationDeLinkPrincipleForUserIdAsyncPostPath = '/api/v1/Registration/deLinkPrincipleForUserIdAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RegistrationDeLinkPrincipleForUserIdAsyncPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationDeLinkPrincipleForUserIdAsyncPost$Response(params?: {
    context?: HttpContext
    body?: EstateAgentDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<GenericResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, RegistrationService.ApiV1RegistrationDeLinkPrincipleForUserIdAsyncPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<GenericResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1RegistrationDeLinkPrincipleForUserIdAsyncPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationDeLinkPrincipleForUserIdAsyncPost(params?: {
    context?: HttpContext
    body?: EstateAgentDto
  }
): Observable<GenericResultDto> {

    return this.apiV1RegistrationDeLinkPrincipleForUserIdAsyncPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<GenericResultDto>) => r.body as GenericResultDto)
    );
  }

}

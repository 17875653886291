<main class="main-container">
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <div class="container">
        <form novalidate id="registerForm" #registerForm="ngForm" class="submission-form">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading-1">
                        Registration For Log-In:
                    </h1>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="heading-4">
                        Contact Details
                    </h4>
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="name">Name:</label>
                </div>
                <div class="col-8">
                    <input required id="name" name="name" type="text" placeholder="Mandatory" #name="ngModel"
                        [(ngModel)]="userForm.name"
                        [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="surname">Surname:</label>
                </div>
                <div class="col-8">
                    <input required id="surname" name="surname" type="text" placeholder="Mandatory" #surname="ngModel"
                        [(ngModel)]="userForm.surname"
                        [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="landLine">Landline:</label>
                </div>
                <div class="col-8">
                    <input id="landLine" name="landLine" type="text" class="not-required"
                        [(ngModel)]="userForm.landLine">
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="mobile">Mobile:</label>
                </div>
                <div class="col-8">
                    <input required id="mobile" name="mobile" type="text" placeholder="Mandatory" #mobile="ngModel"
                        minlength="10" [(ngModel)]="userForm.mobile"
                        [ngClass]="{'invalid-input': mobile.invalid, 'valid-input': mobile.valid}">
                    <small class="user-form-control-feedback"
                        *ngIf="(mobile.errors && (mobile.dirty || mobile.touched)) && mobile.errors.minlength">
                        *Mobile number must contain at least 10 digits.
                    </small>
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="confirmMobile">Confirm mobile:</label>
                </div>
                <div class="col-8">
                    <input required id="confirmMobile" name="confirmMobile" type="text" placeholder="Mandatory"
                        #confirmMobile="ngModel" [(ngModel)]="userForm.confirmMobile"
                        [ngClass]="{
                            'invalid-input': (mobile.value != confirmMobile.value) && (confirmMobile.dirty || confirmMobile.touched),
                            'valid-input': (mobile.value == confirmMobile.value) && (confirmMobile.dirty || confirmMobile.touched)}">
                    <small class="user-form-control-feedback" [ngClass]="{
                            'confirm-invalid': (mobile.value != confirmMobile.value) && (confirmMobile.dirty || confirmMobile.touched),
                            'confirm-valid': mobile.value == confirmMobile.value}">
                        *Mobile numbers do not match.
                    </small>
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="email">Email:</label>
                </div>
                <div class="col-8">
                    <input required id="email" name="email" type="text" placeholder="Mandatory" #email="ngModel"
                        [pattern]="emailPattern" [(ngModel)]="userForm.email" [ngClass]="{
                            'invalid-input': email.invalid,
                            'valid-input': email.valid}">
                    <small class="user-form-control-feedback" *ngIf="email.errors && (email.dirty || email.touched)">
                        *Must be a valid email address.
                    </small>
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="confirmEmail">Confirm email:</label>
                </div>
                <div class="col-8">
                    <input required id="confirmEmail" name="confirmEmail" type="text" placeholder="Mandatory"
                        #confirmEmail="ngModel" [pattern]="emailPattern" [(ngModel)]="userForm.confirmEmail"
                        [ngClass]="{
                            'invalid-input': (email.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched),
                            'valid-input': (email.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                    <small class="user-form-control-feedback" [ngClass]="{
                            'confirm-invalid': (email.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched),
                            'confirm-valid': email.value == confirmEmail.value}">
                        *Emails do not match.
                    </small>
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="userRole">User Role:</label>
                </div>
                <div class="col-8">
                    <mat-select placeholder="userRole" id="userRole" name="userRole" type="mat-select"
                        [(ngModel)]="selectedRole" [disabled]="rolesCanNotBeEdited">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let roleItem of roleList" [value]="roleItem">{{roleItem.name}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="row paragraph mt-4">
                <label class="bold mb-2">
                    <span class="burnt-red">|</span>
                    Are you a resident of the Republic of South Africa?
                </label>
                <label class="mt-1">
                    <input type="radio" id="yes" name="isRsaResident" [(ngModel)]="isRsaResident" [value]="true">
                    Yes
                </label>
                <label class="mt-1">
                    <input type="radio" id="no" name="isRsaResident" [(ngModel)]="isRsaResident" [value]="false">
                    No
                </label>
            </div>
            <div *ngIf="isRsaResident" class="row paragraph">
                <div class="col-4">
                    <label for="idNumber">Your ID number:</label>
                </div>
                <div class="col-8">
                    <input required id="idNumber" name="idNumber" type="text" placeholder="Mandatory"
                        #idNumber="ngModel" pattern="^\d{13}$" [(ngModel)]="userForm.idNumber" [ngClass]="{
                            'invalid-input': idNumber.invalid,
                            'valid-input': idNumber.valid}">
                    <small class="user-form-control-feedback"
                        *ngIf="(idNumber.errors && (idNumber.dirty || idNumber.touched)) && idNumber.errors.pattern">
                        *Must contain exactly 13 digits.
                    </small>
                </div>
            </div>
            <div *ngIf="!isRsaResident" class="row paragraph">
                <div class="col-4">
                    <label for="passportNumber">Your passport number:</label>
                </div>
                <div class="col-8">
                    <input required id="passportNumber" name="passportNumber" type="text" placeholder="Mandatory"
                        #passportNumber="ngModel" pattern="^\d{9}$" [(ngModel)]="userForm.passportNumber" [ngClass]="{
                            'invalid-input': passportNumber.invalid,
                            'valid-input': passportNumber.valid}">
                    <small class="user-form-control-feedback"
                        *ngIf="(passportNumber.errors && (passportNumber.dirty || passportNumber.touched)) && passportNumber.errors.pattern">
                        *Must contain exactly 9 digits.
                    </small>
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4"></div>
                <div class="col-8">
                    <b>Please check spelling and digits carefully.</b>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4 class="heading-4 mt-2">
                        Log In Details
                    </h4>
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="userName">Username:</label>
                </div>
                <div class="col-8">
                    <input required id="username" name="username" type="text" placeholder="Mandatory"
                        #username="ngModel" [(ngModel)]="userForm.userName">
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="password">Password:</label>
                </div>
                <div class="col-8">
                    <input required id="password" name="password" type="password" placeholder="Mandatory"
                        #password="ngModel" [pattern]="passwordPattern" [(ngModel)]="userForm.password" [ngClass]="{
                            'invalid-input': password.invalid,
                            'valid-input': password.valid}">
                    <small class="user-form-control-feedback"
                        *ngIf="password.errors && (password.dirty || password.touched)">
                        *Must contain minimum eight characters, at least one uppercase letter, one lowercase
                        letter, one number and one special character.
                    </small>
                </div>
            </div>
            <div class="row paragraph">
                <div class="col-4">
                    <label for="confirmPassword">Confirm password:</label>
                </div>
                <div class="col-8">
                    <input required id="confirmPassword" name="confirmPassword" type="password" placeholder="Mandatory"
                        #confirmPassword="ngModel" [pattern]="passwordPattern" [(ngModel)]="userForm.passwordConfirm"
                        [ngClass]="{
                            'invalid-input': (password.value != confirmPassword.value) && (confirmPassword.dirty || confirmPassword.touched),
                            'valid-input': (password.value == confirmPassword.value) && (confirmPassword.dirty || confirmPassword.touched)}">
                    <small class="user-form-control-feedback" [ngClass]="{
                            'confirm-invalid': (password.value != confirmPassword.value) && (confirmPassword.dirty || confirmPassword.touched),
                            'confirm-valid': password.value == confirmPassword.value}">
                        *Passwords do not match.
                    </small>
                </div>
            </div>
            <div class="row paragraph col-12">
                <span>
                    By clicking submit, you agree to the Property Matrix
                    <span class="underline">Terms & Conditions</span>,
                    <span class="underline">Privacy Policy</span> and
                    <span class="underline">Cookie Preferences</span>
                    and consent for your personal information to be processed according to the POPI Act.
                </span>
            </div>
            <div class="row paragraph col-12">
                <div class="custom-checkbox-container">
                    <input type="checkbox" id="confirmConsentMarketing" name="confirmConsentMarketing"
                        #confirmConsentMarketing="ngModel" [(ngModel)]="confirmConsent" class="custom-checkbox">
                    <label for="confirmConsentMarketing" class="custom-checkbox-label">
                        I agree to receive marketing communications via email & SMS
                    </label>
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div class="text-center">
                    <button class="button" type="submit" (click)="register()"
                        [disabled]="!registerForm.valid || loading">
                        SUBMIT
                    </button>
                </div>
            </div>
        </form>
    </div>
</main>
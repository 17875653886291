import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { RegistrationService } from 'src/app/services/property-matrixV2/services';
import { EstateAgentInviteDto } from 'src/app/services/property-matrixV2/models';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invite-estate-agent-dialog',
  templateUrl: './invite-estate-agent-dialog.component.html',
  styleUrls: ['./invite-estate-agent-dialog.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})
export class InviteEstateAgentDialogComponent implements OnInit {

  estateAgentInvite: EstateAgentInviteDto = {};

  firstName: string | null = null;
  lastName: string | null = null;
  newRegisterLink: string | null = "www.propertymatrix.agentregistration.com";

  constructor(
    private notificationService: NotificationService,
    private registrationService: RegistrationService,
    public dialogRef: MatDialogRef<InviteEstateAgentDialogComponent>
  ) { }

  ngOnInit(): void { }

  sendInvite(): void {
    this.estateAgentInvite.invitee = `${this.firstName} ${this.lastName}`;

    this.registrationService.apiV1RegistrationInviteEstateAgentPost({
      body: this.estateAgentInvite
    }).subscribe({
      next: () => {
        this.dialogRef.close();
        this.notificationService.showSuccessMessage('Success', 'Invite sent successfully.');
      },
      error: (_error) => {
        this.notificationService.showErrorMessage('Error', 'Could not send invite.');
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
